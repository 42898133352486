import React, {useState} from 'react';
import { Link, Outlet } from "react-router-dom";
import { ModalAdmin } from '../../../../components/Modal';

const Projects = ({ dados, loading }) => {
  const statusMap = {
    denied: 'Negado',
    blank: 'Não finalizado',
    approved: 'Aprovado',
    submitted: 'Aguardando aprovação'
  };

  const urlMarketplace = process.env.REACT_APP_URL;

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState({})

  const infoOwner = (creatorName, address, email, phone, googleWallet = false, cpf, nft, collection, purchased, pag) => {
    setModalOpen(true);
    setModalData({
      name: creatorName,
      address: address,
      email: email,
      phone: phone,
      googleWallet: googleWallet,
      cpf: cpf,
      nft: nft,
      collection: collection,
      purchased: purchased,
      pag: pag
    })
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  return (
    <>
    <table className="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Projeto</th>
          <th>Criado por</th>
          <th>Status</th>
          <th>Criado em</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={6} className="text-center py-3">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </td>
          </tr>
        ) : (
          dados.map((item, index) => (  
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td><strong className='cursor-pointer' onClick={() => infoOwner(item.CreatorName, item.CreatorWallet, item.CreatorEmail, item.CreatorPhone, item.CreatorGoogleWallet, item.CreatorCpf, item.name, item.collectionName, item.purchased, index.pix)}>{item.CreatorName}</strong></td>     
              <td>
                {/* {statusMap[item.status]} */}
                { item.status === 'approved'
                    && <span className="badge text-bg-success">Aprovado</span> }

                { item.status === 'denied'
                    && <span className="badge text-bg-danger">Negado</span> }

                { item.status === 'submitted'
                    && <span className="badge text-bg-info">Aguardando aprovação</span> }

                { item.status === 'blank'
                    && <span className="badge text-bg-warning">Não finalizado</span> }
              </td>
              <td>{item.created}</td>
              <td>
                <Link to={`/admin/collections/edit/${item.collectionId}`} className="btn btn-sm btn-outline-primary">
                    <i className="bi bi-pencil-fill"/>
                </Link>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </table>
    {isModalOpen && (
        <ModalAdmin isOpen={isModalOpen} location="dashboard" closeModal={handleCloseModal} label="Dados do comprador">
          <h4 className="fw-semibold m-0">{modalData.name}</h4>
          <p className='m-0'>{modalData.address} <small>{modalData.googleWallet ? 'Google' : 'Metamask'}</small></p>
          <p className='m-0 text-underline'>Comprou o item <strong>{modalData.nft}</strong> da coleção <strong>{modalData.collection}</strong> pagando com <strong>{modalData.pag ? 'PIX' : 'crypto'}</strong> em {modalData.purchased}.</p>
          <hr className='border-secondary' />
          <ul className='m-0 ps-3'>
            <li className='pb-1'>CPF: {modalData.cpf}</li>
            <li className='pb-1'>E-mail: {modalData.email}</li>
            <li>Telefone: {modalData.phone}</li>
          </ul>
        </ModalAdmin>
      )}
    </>
  );
};

export default Projects;