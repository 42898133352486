import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

import { toast } from "react-toastify";
import price from "../services/price";
import { pinata} from "../services/contract.service";

const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;

export const truncateAddress = (address) => {
  if (!address) return "No Account";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toHex = (num) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};

export const categories = {
  art: "Arte",
  dao: "DAO",
  impacto: "Impacto",
  fotografia: "Fotografia",
};

export const translateCategory = (categorie) => {
  return categories[categorie];
};

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export const getPrice = async (quote) => {
  const response = await price({
    quote: quote,
  });
  return response.data;
};

export const calcPriceBrlNft = (amount, crypto, priceNFT) => {
  let priceC = localStorage.getItem("price");
  const priceCoin = JSON.parse(priceC);

  const price = priceCoin[crypto];
  const amountPrice = parseFloat(priceNFT * price).toFixed(2);

  return "R$ " + amountPrice.toString().replace(".", ",");
};

export const uploadFile = async (file, folder, fileName = null) => {
  let destination = `${folder}${file.name}`;
  if (fileName != null) {
    destination = `${folder}${fileName}`;
  }

  const storageRef = ref(storage, destination);
  const metadata = {
    contentType: file.type,
  };

  const uploadTask = await uploadBytes(storageRef, file, metadata);

  
  return await getDownloadURL(uploadTask.ref);
};

export const copyAddress = (text) => {
  toast.success("Link copiado para a área de transferência.", {});
  navigator.clipboard.writeText(text);
};

export const checkWhitelistDate = (data) => {
  const inititalDateWhiteListed = new Date(data.begin_waitlist_date * 1000);
  const endlDateWhiteListed = new Date(data.end_waitlist_date * 1000);
  const today = new Date();

  return today >= inititalDateWhiteListed && today <= endlDateWhiteListed
    ? true
    : false;
};

export const generatePinata = async (collection, nft) => {
  const response = await pinata(
    {
      collection: collection,
      image: nft,
      amount: 1,
      collectionFB: collectionFirestore,
      imagesFB: imagesFirestore
    }
  );

  if (response === undefined) {
    throw new Error("Erro ao gerar imagem");
  }

  if (!response.success) {
    throw new Error(
      "Esse token está indisponível para compra porque pertence a outro usuário"
    );
  }

  return { ipfs: response.ipfs, nfts: response.nfts };
};

export const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, "");

  if (cpf.length !== 11) {
    return false;
  }

  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let remainder = sum % 11;
  let digit1 = remainder < 2 ? 0 : 11 - remainder;

  if (parseInt(cpf.charAt(9)) !== digit1) {
    return false;
  }

  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }

  remainder = sum % 11;
  let digit2 = remainder < 2 ? 0 : 11 - remainder;

  if (parseInt(cpf.charAt(10)) !== digit2) {
    return false;
  }

  return true;
};

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const getIpAddress = async () => {
  const response = await fetch("https://api.ipify.org/?format=json");
  const data = await response.json();
  const ipAddress = data.ip;
  return ipAddress;
};

export const getGeolocation = () => {
  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      return `${latitude},${longitude}`;
    });
  }
};

export const walletFormat = (wallet) => {
  if (wallet.length <= 10) {
    return wallet;
  }

  const primeiraParte = wallet.substring(0, 5);
  const ultimaParte = wallet.substring(wallet.length - 6);

  return `${primeiraParte}...${ultimaParte}`;
};

export function limitarString(string, limite) {
  if (string.length <= limite) {
    return string;
  } else {
    return string.substring(0, limite) + "...";
  }
}

export const replaceWithDashes = (str) => {
  if (typeof str === 'string') {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9_-]/g, '-')
      .replace(/-{2,}/g, '-')
      .replace(/^-|-$/g, '')
      .toLowerCase();
  }
  return '';
};


