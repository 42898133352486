import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2";
import {swalCustom} from "components/SweetAlert";
import {newsletter as sendEmail} from "services/newsletter";
import { useTranslation } from 'react-i18next';

export const NewsLetter = () => {

    const [value, setValue] = useState('');
    const { t, i18n } = useTranslation();
    
    const sendNewsletter = async () => {

        Swal.close()

        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            swalCustom.fire({
                title: 'Erro',
                icon: 'error',
                html: t('nlInvalidEmail'),
                allowOutsideClick: true,
                timer: 4000,
            })
            return;
        }
        
        const response = await sendEmail({email : value});
        
        if(!response.success) {
            swalCustom.fire({
                title: 'Erro',
                icon: 'error',
                html: response.error,
                allowOutsideClick: true,
                timer: 4000,
            })
            setValue('');
            return;
        }

        swalCustom.fire({
            title: 'Sucesso!',
            icon: t('success'),
            html:  t('nlSuccess'),
            allowOutsideClick: true,
            timer: 4000,
        })
        setValue('');
       
    };

    return (
        <div className="px-4 text-center" style={{padding: "130px 0"}}>
            <h1 className="display-6 ff-bold" data-aos="fade-up" data-aos-delay="200">{t('subscribeNl')}</h1>
            <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">

                <div className="btn-toolbar justify-content-between my-4" role="toolbar"
                     aria-label="Toolbar with button groups" data-aos="fade-left" data-aos-delay="200">
                    <div className="input-group input-group-lg mb-3">
                        <input value={value} onChange={(e) => setValue(e.target.value)} type="text" className="form-control rounded-start-pill" placeholder={t('nlPlaceholder')}
                               aria-label="Recipient's username" aria-describedby="button-addon2"/>
                        <button onClick={() => sendNewsletter()} className="btn btn-primary" type="button" id="button-addon2">
                            <FontAwesomeIcon icon={ faArrowRight } />
                        </button>
                    </div>
                </div>

            </div>
            <div className="col-lg-6 mx-auto">
                <p className="ff-medium fs-5 mb-4" data-aos="fade-up" data-aos-delay="300">
                    {t('listOfEmails')}
                </p>
            </div>
        </div>
    )
}
