import React from 'react';

export function CpfInput({placeholder,cpf,setCpf}) {

const handleCpfChange = (event) => {
  let value = event.target.value;

  value = value.replace(/\D/g, '');

  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  setCpf(value);
};

  return (
    <input
      type="text"
      maxLength={14}
      value={cpf}
      style={{padding:'12px'}}
      onChange={handleCpfChange}
      placeholder={placeholder}
    />
  );
}
