import { useEffect, useState } from 'react'
import { useApp } from "web3/providers/AppContext";
import {FirstStepPix} from "./Steps/firstStep"
import {SecondStepPix} from "./Steps/secondStep"
import {ThirdStepPix} from "./Steps/thirdStep"
import {FourthStepPix} from "./Steps/fourthStep"
import { FifthStep } from './Steps/fifthStep'
import {SixthStepPix} from "./Steps/sixthStep"
import {SuccessStep} from "./Steps/successStep"
import {ErrorStep} from "./Steps/errorStep"
import {useCollection} from "../../web3/providers/CollectionContext";
import {createRfq,updateRfq} from "../../services/pix.service";
import {checkNft}  from "services/contract.service";
import {LoadingModal} from 'components/Loading';
import { useParams, useLocation } from "react-router-dom";


export const  CheckoutPix = ({isOpen,modalLabel,closeModal,setIsOpenToBuy}) => {
const {nft ,collectionData} = useCollection();  
const { wallet, creator } = useApp();
const [rfqId, setRfqid] = useState(null);
const [isLoading, setIsLoading] = useState(false);
const [nftBought, setNftBought] = useState(false);
const [statusRFQ, setStatusRFQ] = useState({success:true});

const [screen,setScreen] = useState('firstStep')
const [emailValue, setEmailValue] = useState(creator?.email);

const [invoice, setInvoice] = useState();
const location = useLocation();
const affiliate = ( wallet ) => {
  const queryParams = new URLSearchParams(location.search);
  const affiliateAddress = queryParams.get("address");

  return {
      wallet:  affiliateAddress ? affiliateAddress : wallet,
      type: affiliateAddress ? "2" : "1"
  }
};

const payload = {
  network: nft.network,
  contract: nft.contract,
  amount: 1,
  priceNFT: nft.nftValue,
  wallet: wallet,
  cid: "crowd.com",
  affiliateWallet: wallet, 
  affiliateType: 1
}

useEffect(() => {
  if(!isOpen){
    setScreen('firstStep')
  }
},[isOpen])

useEffect(() => {
  async function fetchData() {   

    setIsLoading(true);  
    const checkIsValiable = await checkNft (nft?.imageId);
    if(!checkIsValiable) {
      setStatusRFQ({
        success:false,
        message: "NFT já adquirido"
      });
      setIsLoading(false);
      return;
    }
    const checkAfiliation = affiliate(wallet);

    payload.affiliateWallet = checkAfiliation.wallet;
    payload.affiliateType = checkAfiliation.type;

    const response = await createRfq(payload);
    if(!response.success) {
      setStatusRFQ(response);
      setIsLoading(false);
      return;
    }
    setRfqid(response.rfq);
    localStorage.setItem(nft?.imageId,JSON.stringify(response.rfq))
    setIsLoading(false);
  }

  if(!JSON.parse(localStorage.getItem(nft?.imageId))){
   
    fetchData();
    
  }else{

    async function updateData() {  
      
      setIsLoading(true); 
      const checkIsValiable = await checkNft (nft?.imageId);
      if(!checkIsValiable) {
        setStatusRFQ({
          success:false,
          message: "NFT já adquirido"
        });
        setIsLoading(false);
        return;
      }
      const rfq = JSON.parse(localStorage.getItem(nft?.imageId));  
      payload.rfqId = rfq.id;
      const response = await updateRfq(payload);
      
      setIsLoading(false);
      if(!response.success) {  
        localStorage.removeItem(nft?.imageId);
        setStatusRFQ(response);
        fetchData();
        return;
      }
      setRfqid(rfq)
    }
    updateData();
    
  }

},[])



function changeToSecondStep(){
  setScreen('secondStep')
  modalLabel('Confirmar carteira e e-mail')
}

function changeToThirdStep(){
  setScreen('thirdStep')
  modalLabel('Confirmar e-mail')
}

function changeToFourthStep(){
  setScreen('fourthStep')
  modalLabel(' Identificação pessoal')
}

function changeToFifthStep(){
  setScreen('fifthStep')
  modalLabel('Efetuar pagamento')
}

function changeToSixthStep(){
  setScreen('sixthStep')
  modalLabel('')
}

function changeToSuccessStep(){
  setScreen('successStep')
  modalLabel('')
}

if(isLoading){
  return(
    <LoadingModal/>
  )
}

if(!statusRFQ.success){  
 return(<ErrorStep errorMsg={statusRFQ?.message} buttonLabel={'Refazer compra'} changeStep={closeModal} nft={nft}/>)
}



if(rfqId) {
  return(
    <>
     {screen === "firstStep" && <FirstStepPix changeStep={changeToSecondStep} rfqId={rfqId}/> }
     {screen === "secondStep" && <SecondStepPix emailValue={emailValue} setEmailValue={setEmailValue} changeStep={changeToThirdStep} closeModal={closeModal}/>}
     {screen === "thirdStep" && <ThirdStepPix emailValue={emailValue} changeStep={changeToFourthStep}/>}
     {screen === "fourthStep" && <FourthStepPix closeModal={closeModal} changeStep={changeToFifthStep} emailValue={emailValue} rfqId={rfqId} invoice={invoice} setNftBought={setNftBought} setInvoice={setInvoice} buttonLabel={<div> Pagar <i className="fa-brands fa-pix me-2"/> </div>} />}
     {screen === "fifthStep" && <FifthStep buttonLabel={"Nova Compra"} nft={nft} closeModal={closeModal} changeStep={changeToSixthStep} rfqId={rfqId} invoice={invoice}/>}
     {screen === "sixthStep" && <SixthStepPix rfqId={rfqId} closeModal={closeModal} collectionData={collectionData} nft={nft} changeStep={changeToSuccessStep} nftBought={nftBought} setIsOpenToBuy={setIsOpenToBuy} />}
     {screen === "successStep" && <SuccessStep rfqId={rfqId} nft={nft} changeStep={closeModal} buttonLabel={"Nova Compra"} setIsOpenToBuy={setIsOpenToBuy} collectionData={collectionData} />}
    </>
   )
}


}