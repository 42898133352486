import styled from "styled-components";

export const LoginModalContainer = styled.div`
    display: flex;
    margin: 30px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .google-buttons{
        display: flex;
        align-items: center;
        gap:10px;
        border: none;
        background-color: #ee2b7a;
        color: #fff;
        padding: 0.8rem 2.2rem;
        border-radius: 30px;

    }

   
`

export const Separator = styled.div`
    width: 100%;
    font-size: 14px;
    color: #a8a8b3;

    margin: 32px 0;
    display: flex;
    align-items: center;

        &::before{
            content: '';
            flex: 1;
            height: 1px;
            background:  #a8a8b3;
            margin-right: 16px;
        }
        &::after{
            content: '';
            flex: 1;
            height: 1px;
            background: #a8a8b3;
            margin-left: 16px;
        }
`

export const GoogleConectButton = styled.button`
        display: flex;
        align-items: center;
        gap:10px;
        border: none;
        background-color: #ee2b7a;
        color: #fff;
        padding: 0.8rem 2.2rem;
        border-radius: 30px;
        letter-spacing: 0.04rem;
        font-family: 'Figtree Font';

        &:disabled{
            opacity: 0.6;
        };

        .loged-button{
        border: none;
        background-color: #ee2b7a;
        color: #fff;
        padding: 0.6rem 1.5rem;
        border-radius: 30px;
    }

    .button-container{
        display: flex;
        align-items: center;
        gap:10px;
    }
`