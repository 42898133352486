import React from 'react';
import Select from 'react-select';
import brasilIcon from '../../assets/img/flags/br.png'
import euaIcon from '../../assets/img/flags/en.png'
import spanishIcon from '../../assets/img/flags/es.png'

export function LanguageSelector({ i18n }) {
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            padding: 10,
            display: 'flex',
            alignItems: 'center',
            width:100
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            return { ...provided, opacity };
        }
    };

    const options = [
        {
            value: 'en',
            label: 'EN',
            icon: euaIcon,
        },
        {
            value: 'es',
            label: 'ES',
            icon: spanishIcon,
        },
        {
            value: 'pt',
            label: 'PT',
            icon: brasilIcon,
        }
    ];

    const formatOptionLabel = (option) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={option.icon} alt={option.label} style={{ width: '20px', marginRight: '10px' }} />
            {option.label}
        </div>
    );

    const handleChange = (selectedOption) => {
        i18n.changeLanguage(selectedOption.value);
    };

    return (
        <Select 
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            options={options}
            defaultValue={options.find(option => option.value === i18n.language)}
            onChange={handleChange}
        />
    );
}

