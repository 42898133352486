import ModalDefault from "components/ModalDefault";
import useAuth from "hooks/useAuth";
import { useEffect,useState } from "react";

import { useApp } from "web3/providers/AppContext";
import {signInWithPopup} from "firebase/auth"
import ButtonConnect from "../ButtonConnect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { LoginModalContainer ,Separator,GoogleConectButton} from "./styles";
import { useTranslation } from 'react-i18next';

export function ConnectModal({showModal,handleToggleModal,label,setShowModal}){

    const {googleAccount} = useAuth()
    const { handleGoogleLogin,disconnectGoogle,register } = useApp()
    const isMetaMaskMobile = /MetaMask/i.test(navigator.userAgent);
    const { t, i18n } = useTranslation();

    const handleLogin = async  () => {
        if(isMetaMaskMobile){
            return
        }
        await handleGoogleLogin()
        setShowModal(false)
    }

    return(
        <>
        <ModalDefault 
        isOpen={showModal}
        closeModal={handleToggleModal}     
        closeButton={handleToggleModal}
        label={label}
        >

        <LoginModalContainer>

            <ButtonConnect closeModal={setShowModal} />

            <Separator>ou</Separator>

        {!googleAccount ? 
        <>
        <GoogleConectButton onClick={handleLogin} disabled={isMetaMaskMobile}>
            {t(('connectWithGoogle'))}
            <FontAwesomeIcon icon={faGoogle} />
        </GoogleConectButton> 
       {isMetaMaskMobile && ( <span style={{color:'#ee2b7a',margin:0,padding:0,fontSize:'12px',marginTop:'10px',fontWeight:'bold'}}>{t('googleErrorOnMetamask')}</span>)}
        </>: 
            <GoogleConectButton className="loged-button" onClick={disconnectGoogle} >
                <div className="button-container">  
                <img style={{width:'40px',borderRadius:'999px'}} src={googleAccount.avatar}/> 
                    <span>{googleAccount?.email}</span> 
                </div>
            </GoogleConectButton>}

        </LoginModalContainer>
       </ModalDefault>
       </>
      
    )
}