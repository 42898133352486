import React from "react";

import {networkParams} from "web3/networks/networks";
import {uploadFile} from "utils/utils";
import price from "services/price";

import {PopoverBody, UncontrolledPopover} from "reactstrap";
import Swal from "sweetalert2";

import * as validator from "wallet-validator";
import {ethers} from "ethers";
import Web3 from "web3";
import url from "url";
const env = process.env.REACT_APP_ENVIRONMENT;

export const RequireMessage = ({ message }) => (
    <p className="text-danger pt-1 mb-0 small text-start" style={{ textAlign: "center" }}>
            {" "}
        {message ?? "Campo obrigatório"}
    </p>
);

export const InfoCollection = props => {
    return (
        <UncontrolledPopover placement="bottom" target={props.target} trigger="focus">
            <PopoverBody>
                <div className="d-grid">
                    <small>{props.message}</small>
                </div>
            </PopoverBody>
        </UncontrolledPopover>
    );
};

export const checkGasForDeploy = async (providerChain, userBalance) => {
    const gasPrice = await providerChain.getGasPrice();

    const etherGasPrice = ethers.utils.formatUnits(gasPrice, "ether");

    return (userBalance > etherGasPrice);
};

export const sendImagesToFirestore = async (images, folder) => {
    return await Promise.all(
        images.map(async (element, index) => {
            const fileUrl = await uploadFile(element, folder);
            const urlParts = url.parse(fileUrl, true);
            const token = urlParts.query.token;
            return {
                name: element.name,
                url: fileUrl,
                token: token,
                status: 0,
                quantity: element.quantity,
                price: element.price
            };
        }),
    ).finally();
};

export const sendFilesToFirestore = async (files, folder) => {

    if(files.length === 0) {        
        return [];
    }

    return await Promise.all(
        files.map(async (element, index) => {
            const fileUrl = await uploadFile(element, folder);
            return {
                name: element.name,
                url: fileUrl
            };
        }),
    ).finally();
};

export const CSVToArray = data =>
    data.split(",").map(function (line) {
        return line.replace(/\s/g, "");
});


export const getPrice = async (network, amountRequested, setFieldValue, input, setValidAmount) => {
    if (!network) {
        setFieldValue(input, "R$ 0,00");
        return false;
    }
    const quote = networkParams[network].crypto;
    const response = await price({
        quote: quote,
    });

    const amount = parseFloat(response.data * amountRequested).toFixed(2);
    
    if(env === 'prod' && amountRequested < 10) {
        setValidAmount(true);
    } else {
        setValidAmount(false);
    }

    const formattedValue = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
    }).format(amount);

    setFieldValue(input, formattedValue);
};

export function validateNameCollection(value) {
    const result = parseInt(value.substring(0, 1));
    return isNaN(result);
}

export function validateWallet(wallet) {
    return validator.validate(wallet, "ETH");
}

export function validateSalesWallets(value) {
    return !(value > 85 || value < 0);
}

export function validateRoyaltiesWallets(value) {
    return !(value > 10 || value < 0);
}

export function validateMinCrowdFund(value) {
    return !(value < 1 || value > 85);
}

export function validateTotalFirstSale(value) {
    return value === 100;
}

export function validateTotalSecondSale(value) {
    return (value >= 1 && value <= 10);
}

export function transformDate(input, returnDate = false) {
    const [day, month, fullYear] = input.split("/");
    const [ano, horaMin] = fullYear.split(" ");
    const [hour, minute] = horaMin.split(":");
    const dataISO = `${ano}-${month}-${day}T${hour}:${minute}:00.00Z`;
    const data = new Date(dataISO);

    if (returnDate) {
        return data;
    }

    return Math.floor(data.getTime() / 1000.0);
}

export function validateDate(dataStr) {
    if (dataStr.length >= 16) {
        let data = transformDate(dataStr);
        return !isNaN(data);
    }
}

export function validatePercentage(value) {
    if (value > 100) {
        return "Valor acima de 100";
    }
}

export function allowOnlyNumbersAndDots(event) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode === 46) {
        // Permitir o ponto
        return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        // Permitir apenas números
        event.preventDefault();
        return false;
    }
    return true;
}

export function validateMinAmountPerWallet(total, wallet) {
    return wallet <= total;
}

export function validate(value) {
    let error;
    if (!value) {
        error = true;
    }
    return error;
}

export function toChecksumAddress(address) {
    address = address.toLowerCase().replace('0x', '');
    const hash = Web3.utils.sha3(address).replace('0x', '');
    let checksumAddress = '0x';
    for (let i = 0; i < address.length; i++) {
    // If ith digit of hash is 0-7, capitalize corresponding address letter
    if (parseInt(hash[i], 16) > 7) {
        checksumAddress += address[i].toUpperCase();
    } else {
        checksumAddress += address[i];
    }
    }
    return checksumAddress;
}

export function prepareData(data, whitelist, royalties, symbol, images, banner, walletDeploy, reasons_to_believe_files)  {
    return {
        network: data.network,
        maxSupply: parseInt(data.amount),
        shortDescription: data.short_description,
        description: data.description,
        maxMintAmount: parseInt(data.amount_wallet),
        freeMintPerAddressLimit: parseInt(data.quantity_freemint),
        paused: data.contract_paused,
        onlyWhitelisted: data.whitelist,
        name: data.name,
        symbol: data.symbol,
        rootKey: whitelist.rootKey,
        whitelist_addresses: whitelist.addresses,
        isFreeMint: data.freemint,
        nftEtherValue: parseFloat(data.value),
        sellerFeePoints: royalties,
        symbolImage: symbol,
        externalLink: data.external_link,
        images: images,
        banner: banner,
        categories: data.categories,
        wallet: toChecksumAddress(walletDeploy),
        creator: toChecksumAddress(data.wallet_creator),
        artist: (data.wallet_artist !== "") ? toChecksumAddress(data.wallet_artist) : toChecksumAddress(data.wallet_creator),
        benefits: data.benefits,
        reasonsToBelieve: data.reasons_to_believe,
        reasons_to_believe_files: reasons_to_believe_files,
        discord: data.discord,
        twitter: data.twitter,
        instagram: data.instagram,
        begin_waitlist_date: whitelist.begin_waitlist_date,
        end_waitlist_date: whitelist.end_waitlist_date,
        start_public_sale: whitelist.start_public_sale,
        wallet_creator_first_sale: data.wallet_creator_first_sale,
        wallet_creator_second_sale: data.wallet_creator_second_sale,
        wallet_artist_first_sale: data.wallet_artist_first_sale,
        wallet_artist_second_sale: data.wallet_artist_second_sale,
        nftEtherWaitlistValue: whitelist.nftValue,
        crowd_fund_first_sale: data.crowd_fund_first_sale
    };
}
