import { createContext,useState } from "react";

export const AuthContext = createContext();

export default function AuthContextProvider(props){
    const [googleAccount,setGoogleAccount] = useState()
    return(
        <AuthContext.Provider value={{googleAccount,setGoogleAccount}}>
            {props.children}
        </AuthContext.Provider>
    )
}