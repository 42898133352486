import React from "react";
import videoPlaceholder from "assets/img/video-placeholder.png";
import { useTranslation } from 'react-i18next';

export const RenameFiles = ({ fileList, setFieldValue, toggle }) => {
    const { t, i18n } = useTranslation();

    const renameAll = e => {
        e.stopPropagation();
        e.preventDefault();

        const newName = e.target.new_name.value;

        if (!newName) {
            return;
        }

        const newFiles = [...fileList];
        const files = [];

        newFiles.forEach((file, index) => {
            const currentFile = new File([file], newName + " #" + (index + 1), {
                type: file.type,
            });
            currentFile.quantity = newFiles[index].quantity ? newFiles[index].quantity : 1;
            currentFile.price = file.price;
            files.push(currentFile);
        });

        setFieldValue('imagesNft', files)

    };

    const allowOnlyNumbersAndDots = e => {
        
        const charCode = e.which ? e.which : e.keyCode;

        if (charCode === 110 || charCode === 105 || charCode === 46 || charCode === 8 || charCode === 37 || charCode === 39) {    
            return true;
        } else if ((charCode > 47 && charCode < 58 ) || (charCode > 95 && charCode < 106)) {        
            return true;            
        }
        
        e.preventDefault();
        e.stopPropagation();
        
        return;
        
    };

    const saveNft = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        const newName = e.target.new_name.value;
        const newQuantity = e.target.new_quantity.value ? e.target.new_quantity.value : 1;
        // const newPrice = e.target.new_price.value;
        
       

        if (!newName && !newQuantity) {
            return;
        }

        const newFiles = [...fileList];
        
        const oldQuantity = newFiles[index].quantity ? newFiles[index].quantity : 1;
        
        if (newName) {
            const newFile = new File([newFiles[index]], newName + " #" + (index + 1), { type: newFiles[index].type });
            newFile.price = newFiles[index].price;
            newFiles[index] = newFile;
        }
        /* if(newPrice) {
            newFiles[index].price = newPrice;
        } */
        newFiles[index].quantity = newQuantity ? newQuantity : oldQuantity;
        //setFileList(newFiles);
        //setImage(newFiles);

        setFieldValue('imagesNft', newFiles)

        
        e.target.new_name.value = "";
        e.target.new_quantity.value = "";
        //e.target.new_price.value = "";
    };



    return (
        <div className="container align-items-center gap-3">
            <form onSubmit={renameAll} id="renameAll" className="mb-4">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-5">
                        <label className="form-label">{t('renameImgs')}</label>
                        <div className="d-flex gap-2">
                            <input className="form-control" name="new_name" />
                            <button className="btn btn-primary rounded-2 rounded-start-0" type="submit">
                            {t('rename')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>

            <hr />

            <div className="row g-3 mt-2 mb-4" style={{ maxHeight: "calc(100vh - 25rem", overflowX: "auto" }}>
                { fileList.map((file, index) => (
                    <div className="col-2" key={index}>
                        <div className="border rounded-3 p-2 d-flex flex-column justify-content-center gap-2 align-items-center">
                            <div className="position-relative">
                                <img
                                    src={file.type === "video/mp4" ? videoPlaceholder : URL.createObjectURL(file)}
                                    alt={file.name}
                                    style={{
                                        objectFit: "cover",
                                    }}
                                    height="140px"
                                    width="100%"
                                    className="rounded-3"
                                />
                                <span
                                    className="position-absolute bg-dark p-1 fs-6 rounded-2"
                                    style={{
                                        left: "3px",
                                        top: "3px",
                                        opacity: 0.9,
                                        color: "white",
                                    }}>
                                    #{index + 1}
                                </span>
                            </div>
                            <span>
                                <b>{file.quantity ? file.quantity : 1}x</b> {file.name}, {file.price}
                            </span>
                            <form onSubmit={eventForm => saveNft(eventForm, index)} id={`formItem${index}`}>
                                <div className="d-grid gap-2">
                                    <input type="text" name="new_name" className="form-control form-control-sm" placeholder={t('newName')} />
                                    <input type="number" name="new_quantity" className="form-control form-control-sm" placeholder={t('newQuantity')} min={1} />                                    
                                    <button className="btn btn-primary  w-100" type="submit">
                                        {t('save')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                ))}
            </div>

            <hr />

            <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-primary btn-lg" type="button" onClick={toggle}>
                    {t('progress')}
                </button>
            </div>
        </div>
    );
};
