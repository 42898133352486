import { Link, Outlet } from "react-router-dom";
import {HeaderPage} from "../../components/HeaderPage";
import {useQuery} from "react-query";
import {Toast} from "../../components/Alert";
import {useCategories} from "../../hooks/useApi";

export const CategoriesAdmin = () => {

    const api = useCategories()

    const { isFetching, data } = useQuery(["categories_list"],
        () => {
            return api.getCategories();
        }, {
            onSuccess: (response) => {

            },
            onError: () => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            },
            retry: false,
        }
    )

    return (
        <div className="container">
            <Outlet />

            <HeaderPage
                label="Categorias"
                icon="bi bi-tags"
                description="Adicione e edite as categorias que serão apresentadas para os criadores."
            />

            <div className="card mb-3 border-0 shadow-sm p-3">
                <div className="d-flex justify-content-end">
                    <div>
                        <Link to="add" className="btn btn-success">
                            <i className="bi bi-plus"/> Criar categoria
                        </Link>
                    </div>
                </div>
            </div>

            <div className="card border-0 shadow-sm">
                <div className="">
                    <table className="table mt-1">
                        <thead>
                            <tr>
                                <th className="py-3 ps-3">Categoria</th>
                                <th className="py-3 ps-3">Está visivel na home</th>
                                <th className="py-3 ps-3">Ativo</th>
                                <th className="py-3"></th>
                            </tr>
                        </thead>
                        <tbody>

                        { isFetching &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </td>
                            </tr>
                        }

                        { !data?.length && !isFetching &&
                            <tr>
                                <td colSpan={5} className="text-center py-3">Nenhum registro encontrado</td>
                            </tr>
                        }

                        { !isFetching && data?.map(item =>
                            <tr key={item.id}  valign="middle">
                                <td className="ps-3">
                                    <div className="d-flex align-items-center">
                                        <i className={item?.icon}/>
                                        <span className="fw-bolder p-2"> {item.name} </span>
                                    </div>

                                </td>
                                <td className="ps-3">
                                    <div className="d-flex align-items-center">                                        
                                        <span className="fw-bolder p-2"> {(item.home) ? "Sim" : "Não"} </span>
                                    </div>
                                </td>
                                <td className="ps-3">
                                    <div className="d-flex align-items-center">                                        
                                        <span className="fw-bolder p-2"> {(item.status) ? "Sim" : "Não"} </span>
                                    </div>
                                </td>
                                <td>
                                    <Link to={`edit/${item.id}`} className="btn btn-sm btn-outline-primary">
                                        <i className="bi bi-pencil-fill"/>
                                    </Link>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}
