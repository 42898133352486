import styled from 'styled-components';

export const CardContent = styled.div`
    .card {
        height: 250px;
        padding: 5px;
        border-color: ${props => props.theme.black};
        border-radius: 20px;
        transition: transform 0.5s;
    }
    
   .card:hover {
        transform: translate(0, -8px);
    }

    .text-xs{
        font-size: 14px;
    }

    .card-image{
    display:  flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 20px;
    padding: 10px;
    height: 200px;
    overflow: hidden; /* adicionado para garantir que a imagem não saia das bordas arredondadas do cartão */
}

.card-image img {
    width: 100%;
    border-radius: 8px ;
    object-fit: contain; /* alterado de 'cover' para 'contain' */
    background-color: transparent;
}
  
`;
