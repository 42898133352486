import { Modal, ModalHeader, ModalBody } from 'reactstrap';

export const ModalApp = ({ isOpen, closeModal, label, children, centered= true, size= "" } ) => {
    return (
        <Modal isOpen={isOpen} toggle={closeModal} centered={centered} backdrop={true} size={size}>
            <ModalHeader toggle={closeModal} className="border-0">
                    <p className="fw-bold fs-4 px-3 mb-0">
                        { label }
                    </p>
            </ModalHeader>
            <ModalBody className="pb-4 d-grid gap-3 rounded-bottom">
                { children }
            </ModalBody>
        </Modal>
    );
}
