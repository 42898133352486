import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheck,faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import { useApp } from "web3/providers/AppContext";
import { addTokenMetamask } from "web3/contracts/functions";
import * as S from '../styles'
import { walletFormat } from 'utils/utils';
import useAuth from 'hooks/useAuth';
import { Link } from 'react-router-dom';

export const  SuccessStep = ({buttonLabel,changeStep,nft,setIsOpenToBuy,collectionData}) => {

  const { wallet, connection } = useApp();
  const {googleAccount} = useAuth()

  const handleContinueButton = () => {
    changeStep();
    setIsOpenToBuy(true);
  }

return(
    <S.CheckoutContainer>
      <S.AlertDetalis>
      <FontAwesomeIcon icon={faCheck} size='2x' className='check' />
      <p className='success_p'>Seu token estará em sua Wallet dentro de 1 à 3 <br/>minutos!</p>

      <span className='success_span'>Wallet: {walletFormat(wallet)}</span>

      {!googleAccount && <button onClick={() => addTokenMetamask(nft?.contract, nft?.symbol, nft?.url, connection)}> <FontAwesomeIcon icon={faPlusCircle} size='sm' className='plus' /> Adicionar NFT a sua carteira</button>}
     

      <p className='alert'>Caso o APP da Metamask não abra automaticamente, abra a Metamask manualmente.</p>
      </S.AlertDetalis>

    
      <Link to={`/collection/${collectionData.id}`} style={{color: 'none',textDecoration:'none'}} >   
      <S.ContinueButton onClick={handleContinueButton}>           
        {buttonLabel}
      </S.ContinueButton>
      </Link>


    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>

    </S.CheckoutContainer>
  )

}