import { CardContent } from "./styles";
import {useNavigate} from "react-router-dom";
import { networkParams } from "web3/networks/networks";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export const CardNFT = ({ nft,isProfile }) => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const crypto = networkParams[nft.network]?.crypto;
    const { collection } = useParams();
    const pathnameParts = location.pathname.split('/');

    const queryParams = new URLSearchParams(location.search);
    const affiliateAddress = queryParams.get("address");
    const url = affiliateAddress ? "?address=" + affiliateAddress : "";

    const handleRedirectToCollection = (idImage) => {
        const link = (collection) ? collection : nft.collectionId;
        navigate("/collection/" + link + "/"+ idImage + url)
    }

    return (
        <CardContent>
            <div className="card">
                <div className="card-image mx-2 mt-2">
                    <img
                        src={nft.url}
                        className={`img-fluid rounded-4 ${(nft.status === 1 && pathnameParts[1] !== "profile" ) && 'opacity-50'}`}
                        alt=""
                        onClick={() => handleRedirectToCollection(nft.imageId ? nft.imageId : nft.id)}
                        role="button"
                    />

                    { (nft.status === 1 && pathnameParts[1] !== "profile" ) && <span className="ff-bold">{t('solded')}</span> }
                </div>

                <div className="card-body mx-0 pb-2">
                    <div className="d-flex mb-2">
                        <span className="ff-bold">{nft.name}</span>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-1">

                        <span className="ff-medium fs-5">{nft.nftValue} {crypto}</span>

                        {!isProfile && ( <button
                            className="btn btn-outline-secondary rounded-pill px-3"
                            onClick={() => handleRedirectToCollection(nft.imageId ? nft.imageId : nft.id)}
                        >
                             {t('buy')}
                        </button>)}
                       
                    </div>
                </div>
            </div>
        </CardContent>
    );
};
