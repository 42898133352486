import { useEffect, useState } from "react";

import { Content } from "./styles";
import {Link, useNavigate, useParams} from "react-router-dom";
import { ListNFT } from "components/ListNFT";
import { Loading } from "components/Loading";
import { Share } from "components/Share";

import { getCollection } from "services/collection.service";
import { truncateAddress } from "utils/utils";
import { TabTitle } from "../../utils/utils";

import { networkParams } from "web3/networks/networks";
import format from "date-fns/format";
import ptBR from "date-fns/locale/pt-BR";
import { useApp } from "../../web3/providers/AppContext";
import ModalDefault from "../../components/ModalDefault";
import { LoadMoreButton } from "components/LoadMoreButton";
import { useTranslation } from 'react-i18next';
import { SelectWrapper,Option } from "./styles";
import DataTable from "react-data-table-component";
import { HeaderPage } from "admin/components/HeaderPage";
import {collection, doc, getDoc, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { ModalCollection } from '../../components/ModalCollection';

export const CollectionPage = () => {
    const { t, i18n } = useTranslation();
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [dataImages, setDataImages] = useState([]);
    const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
    const creatorsFirestore =  process.env.REACT_APP_CREATORS_FB; 
    const rfqFirestore = process.env.REACT_APP_RFQS_FB;
    const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;
    const [filterText, setFilterText] = useState("");
    const [images, setImages] = useState([]);    
    const [totalSalesMatic, setTotalSalesMatic] = useState(0);
    const [totalPixPurchases, setTotalPixPurchases] = useState(0);
    const [pixCount, setpixCount] = useState(0);
    const [etherCount, setetherCount] = useState(0);
    const [sumCreator, setSumCreator] = useState(0);
    const [sumEndOfWorld, setSumEndOfWorld] = useState(0);
    TabTitle(`Collection | ${data.name ? data.name : ''}`);

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({})
  
    const infoOwner = (creatorName, address, email, phone, googleWallet = false, cpf, nft, collection, purchased, pag) => {
      setModalOpen(true);
      setModalData({
        name: creatorName,
        address: address,
        email: email,
        phone: phone,
        googleWallet: googleWallet,
        cpf: cpf
      })
    }
  
    const handleCloseModal = () => {
      setModalOpen(false);
    }

    

    const { wallet, connectWallet, layoutHeader } = useApp();
    const navigate = useNavigate();

    const requestCollection = async () => {
        setLoading(true);
        
        const response = await getCollection(collectionId);

        if (!response.contract) {
            navigate('/404')
        } 

        setData(response);
        setDataImages(response.images);
        fetchImages(response);
    };
    

    const filteredItems = images.filter(item => {
    const search = filterText.toLowerCase();
        return (
            (item.name && item.name.toLowerCase().includes(search)) ||
            (item.collectionName && item.collectionName.toLowerCase().includes(search)) ||
            (item.CreatorName && item.CreatorName.toLowerCase().includes(search))
        );
    });

    const fetchImages = async (infoCollection) => {        
        
        const imagesQuery = query(collection(db, imagesFirestore), where("status", "==", 1), where("collectionId", "==", collectionId), orderBy('purchaseAt', 'desc'));

        const fetchedImages = [];
        try{
          const querySnapshot = await getDocs(imagesQuery);
          let sumMatic = 0;                
          let totalItemsCrypto = 0;
          let totalItemsPix = 0;
          let sumPixPurchases = 0;
          let sumSoldMatic = 0;
          for (const imageDoc of querySnapshot.docs) {
              let imageData = imageDoc.data();
              const purchaseAt = new Date(imageData.purchaseAt.seconds * 1000);

              // Converta os nanossegundos em milissegundos e adicione à data
              purchaseAt.setMilliseconds(purchaseAt.getMilliseconds() + Math.round(imageData.purchaseAt.nanoseconds / 1000000));

              const day = purchaseAt.getDate().toString().padStart(2, '0');
              const month = (purchaseAt.getMonth() + 1).toString().padStart(2, '0');
              const year = purchaseAt.getFullYear();
              const hours = purchaseAt.getHours().toString().padStart(2, '0');
              const minutes = purchaseAt.getMinutes().toString().padStart(2, '0');
        
              imageData.purchased = `${day}/${month}/${year} ${hours}:${minutes}`;

              const documentoRef = doc(db, creatorsFirestore, imageData.owner);
              const documentoSnapshot = await getDoc(documentoRef);
              imageData.CreatorName = imageData.owner;
              if (documentoSnapshot.exists()) {
                  const dadosDoDocumento = documentoSnapshot.data();
                  imageData.CreatorName   = dadosDoDocumento.name;
                  imageData.CreatorWallet = dadosDoDocumento.address;
                  imageData.CreatorEmail = dadosDoDocumento.email;
                  imageData.CreatorPhone = dadosDoDocumento.celular;
                  imageData.CreatorGoogleWallet = dadosDoDocumento.isGoogleWallet;
                  imageData.CreatorCpf = dadosDoDocumento.cpf;
                  imageData.CreatorNickname = dadosDoDocumento.nickname;
              } 

              const colecaoRef = doc(db, collectionFirestore, imageData.collectionId);
              const colecaoSnapshot = await getDoc(colecaoRef);
              imageData.collectionName = imageData.collectionId;
              if (colecaoSnapshot.exists()) {
                  const dadosDoDocumento = colecaoSnapshot.data();
                  imageData.collectionName = dadosDoDocumento.name
              }

            if(parseInt(imageData.network) === 80001 || parseInt(imageData.network) === 137) {                        
                sumMatic =  sumMatic + parseFloat(imageData.nftValue);
            }

            if (imageData.pix && imageData.rfqId) {
                  const rfqDoc = await getDoc(doc(db,rfqFirestore, imageData.rfqId));
                  if (rfqDoc.exists()) {
                      const rfqData = rfqDoc.data();
                      if (rfqData && rfqData.rfq && rfqData.rfq.order && rfqData.rfq.order.quote && rfqData.rfq.order.quote.result && rfqData.rfq.order.quote.result.cash && rfqData.rfq.order.quote.result.cash.value) {
                          imageData.realValue = rfqData.rfq.order.quote.result.cash.value;
                          sumPixPurchases += parseFloat(imageData.realValue);
                          totalItemsPix++;
                      }
                  }
              } else {
                const value = parseFloat(imageData.nftValue);                   
                sumSoldMatic =  sumSoldMatic + value;
                totalItemsCrypto++;
            }
              
              fetchedImages.push(imageData);
          }  
          console.log(infoCollection.wallet_creator_first_sale, infoCollection.crowd_fund_first_sale) ;
          const somaCreator = infoCollection.wallet_creator_first_sale ? (sumMatic * parseInt(infoCollection.wallet_creator_first_sale)) / 100 : 0;
          const somaEndOfWorld = infoCollection.crowd_fund_first_sale ? (sumMatic * parseInt(infoCollection.crowd_fund_first_sale)) / 100 : 0;

          setSumCreator(somaCreator);
          setSumEndOfWorld(somaEndOfWorld)

          setetherCount(totalItemsCrypto);
          setTotalSalesMatic(sumSoldMatic);
          setImages(fetchedImages);
          setpixCount(totalItemsPix);
          setTotalPixPurchases(sumPixPurchases);
        } catch (error) {
            console.error('Erro ao obter os últimos documentos:', error);
        }
    };


    useEffect(() => {
        fetchImages();
    }, []);
    
    
    useEffect(() => {
        fetchImages();
    }, []);
    

    const customStyles = {
        table: {
          style: {
            // padding: '20px',
            // backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            overflow: 'hidden',
            fontSize: '16px'
          },
        },
        // headCells: {
        //   style: {
        //     fontSize: '1rem',
        //     backgroundColor: '#f5f5f5',
        //     color: 'white',
        //     padding: '0 16px',
        //     fontWeight:'bold',
        //     fontSize:'16px'
        //   },
        // },
        // rows: {
        //   style: {
        //     fontSize: '16px',
        //     backgroundColor: '#f5f5f5',
        //     borderColor: '#333',
        //     color: 'white',
        //     padding: '10px 0',
        //   },
        //   hoverStyle: {},
        // },
        // cells: {
        //   style: {
        //     backgroundColor: '#f5f5f5',
        //     color: 'white',
        //     // padding: '10px 20px',
        //   },
        // },
        // pagination: {
        //   style: {
        //     backgroundColor: '#f5f5f5',
        //     color: '#ffffff !important',
        //     padding: '20px',
        //     borderRadius: '10px',
        //     marginTop: '10px',
        //   },
        //   pageButtonsStyle: {
        //     color: '#ffffff',
        //     borderColor: '#ffffff',
        //     borderRadius: '10px',
        //     backgroundColor: '#ffffff',
        //     hoverBackgroundColor: '#ffffff',
        //     hoverBorderColor: '#ffffff',
        //     activeBackgroundColor: 'transparent',
        //     activeBorderColor: 'transparent',
        //     marginRight: '5px',
        //     marginLeft: '5px',
        //   },
        //   hoverStyle: {}
        // },
      };

      const imageColumns = [
        {
            name: '#',
            cell: (row, index) => {
                return index + 1;
            },
            sortable: true,
            width: 'fit-content'
        },
        {
            name: 'Item',
            // selector: img=> img.name,
            cell: (row) => (
                <><img src={row.url} width="32" className="rounded-2 me-2" /> {row.name}</>
            ),
            sortable: true,
            grow: 2
        },        
        {
            name: t('payment'),
            selector: img => img.pix ? 'PIX' : 'Crypto',
            sortable: true
        },
        {
            name: t('value'),
            selector: img => {
                if (img.pix && img.realValue) {
                    return parseFloat(img.realValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                } else if (parseInt(img.network) === 1 || parseInt(img.network) === 5) {
                    return `${img.nftValue} Ether`;
                } else if (parseInt(img.network) === 80001 || parseInt(img.network) === 137) {
                    return `${img.nftValue} MATIC`;
                } else {
                    return img.nftValue;
                }
            },
            sortable: true,
            grow: 1
        },
        {
            name: t('purchasedBy'),
            selector: img => img.CreatorName,
            cell: row => (
              <>
              <strong className='cursor-pointer' style={{ cursor: 'pointer' }} onClick={() => infoOwner(row.CreatorName, row.CreatorWallet, row.CreatorEmail, row.CreatorPhone, row.CreatorGoogleWallet, row.CreatorCpf, row.name, row.collectionName, row.purchased, row.pix)}>{!row.CreatorName ? (row.CreatorNickname) : (row.CreatorName)}</strong>
              </>
            ),
            sortable: true
        },
        {
            name: t('wallet'),
            selector: img => img.CreatorWallet,
            sortable: true,
            cell: row => {
                const wallet = row.CreatorWallet;
                if (wallet.length >= 8) {
                    const firstFour = wallet.substr(0, 5);
                    const lastFour = wallet.substr(wallet.length - 4);
                    return `${firstFour}...${lastFour}`;
                }
                return wallet; // Retorna a carteira completa se tiver menos de 8 caracteres
            }
        },
        {
          name: t('date'),
          selector: img => img.purchased,
          sortable: true
        },
    ];

    const tab = (data.wallet == wallet) ? "my-projects": "creator";
    
    const [tabActive, setTabActive] = useState(tab)

    useEffect(() => {
        layoutHeader(2);
    }, [layoutHeader]);

    const { collection: collectionId } = useParams();
    
    useEffect(() => {
        requestCollection().then(() => setLoading(false));
    }, []);

    
    const handleChange = async (event) => {
        const optionValue = event.target.value;
        setSelectedOption(optionValue);
        
        if(optionValue === 'all') {
            requestCollection().then(() => setLoading(false));
        }
        if(optionValue === 'unsold') {
            setLoading(true);
        
            const response = await getCollection(collectionId);
            const filteredResponse = response.images.filter((item) => item.status === 0);
    
            if (!response.contract) {
                navigate('/404')
            } 
    
            setData(response);
            setDataImages(filteredResponse);
            setLoading(false);
        }
    };

    const toggleModal = async () => {
        if(!wallet) {
            const connection = await connectWallet();
            if(connection.address) {
                setIsOpen(!isOpen)
            }
        } else {
            setIsOpen(!isOpen)
        }
    };

    const closeModal = () => {
        setIsOpen(false)
    }

    const date2 = new Date(data?.created_at?.seconds * 1000);

    if (loading) return <Loading />;

    return (
        <Content>
            <div className="banner-collection">
                {data?.banner && <img src={data.banner} className="img-fluid" alt="banner" />}
                <div className="container">
                    <div className="image-collection bg-light rounded-4">
                        <img src={data.symbolImage} className="shadow-sm" alt="symbol" />
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="d-flex gap-3 flex-wrap justify-content-between align-items-center mt-4">
                    <div>
                        <h1 className="ff-bold">{data.name}</h1>

                        <span className="fs-5 ff-light">
                            {t('createdBy')}
                            <Link to={`/creator/${data?.creator?.nickname ? data?.creator?.nickname : data.wallet}`} className="text-decoration-none ff-bold ms-2">
                                {data.creator && data.creator?.nickname ? `@${data.creator.nickname}` : truncateAddress(data.wallet)}
                            </Link>
                        </span>
                    </div>

                    <div className="icons">
                        {data.externalLink &&
                            <Link to={data.externalLink} target="_blank">
                                <i className="fa fa-globe"/>
                            </Link>
                        }
                        {data.instagram &&
                            <Link to={data.instagram} target="_blank">
                                <i className="fa-brands fa-instagram"/>
                            </Link>
                        }
                        { data.discord &&
                            <Link to={data.discord} target="_blank">
                                <i className="fa-brands fa-discord"/>
                            </Link>
                        }
                        { data.twitter &&
                            <Link to={data.twitter} target="_blank">
                                <i className="fa-brands fa-twitter"/>
                            </Link>
                        }
                        <Link to="#" onClick={toggleModal}>
                            <i className="fa fa-share-nodes"/>
                        </Link>
                    </div>
                </div>

                <div className="d-flex flex-wrap gap-3 mt-3 fs-6" style={{alignItems:'center'}}>
                    <span className="ff-normal">
                        Itens <span className="ff-bold">{(data?.totalNfts) ? data.totalNfts : data?.images?.length}</span>
                    </span>
                    ·
                    <span className="ff-normal">
                    {t('createdAt')}
                        <b className="ms-1 date-format">{format(date2, "MMM yyyy", { locale: ptBR })}</b>
                    </span>
                    ·
                    <span className="ff-normal">
                    {t('category')}
                        {data?.categories?.length > 0 && data?.categories.map(category => (
                            <span className="ff-bold mx-1" key={category.name}>
                                {category.name}
                            </span>
                        ))}
                    </span>
                    ·
                    <span className="ff-normal">
                        <span className="ff-bold">{networkParams[data?.network]?.network_name}</span>
                    </span>
                    ·
                    <span className="ff-normal">
                    {t('sortBy')} : {' '}
                    <SelectWrapper value={selectedOption} onChange={handleChange}>
                        <Option value="all">{t('allF')}</Option>
                        <Option  value="unsold">
                        {t('notBuy')}
                        </Option>
                    </SelectWrapper>
                    </span>
                </div>

                <div className="about my-4">
                    <p className="ff-normal">{data?.shortDescription} </p>
                </div>
                {
                    (data.wallet == wallet) ? (
                    <div className="d-flex justify-content-center mt-5">
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                            <button 
                                    onClick={() => setTabActive('my-profile')}
                                    className={`nav-link ${tab === 'creator' ? 'active' : ''}`}
                                    id="pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="pills-profile"
                                    aria-selected="false">
                                    <i className="bi bi-person fs-5" /> NFTS
                                </button>
                            </li>
                            <div>
                            
                                <li className="nav-item" role="presentation">
                                    <button
                                        onClick={() => setTabActive('my-projects')}
                                        className={`nav-link ${tab === 'my-projects' ? 'active' : ''}`}
                                        id="pills-collections-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pills-collections"
                                        type="button"
                                        role="tab"
                                        aria-controls="pills-collections"
                                        aria-selected="false">
                                        <i className="bi bi-tag fs-5" /> Analytics
                                    </button>
                                </li>
                            
                            </div>
                            
                        </ul>
                    </div>
                ): "" } 

                <div className="tab-content" id="pills-tabContent">
                    <div
                        className={`tab-pane fade disabled ${tab === 'creator' ? 'show active' : ''}`}
                        id="pills-profile" role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                    >
                        <ListNFT nfts={dataImages} />
                            { ((dataImages.length !== data.totalNfts) && !data.collectionGroup ) &&
                                <LoadMoreButton nfts={dataImages} setDataImages={setDataImages} collectionData={data} >
                                    {t('loadMore')}
                                </LoadMoreButton>
                            }
                    </div>
                    <div className={`tab-pane fade ${tab === 'my-projects' ? 'show active' : ''}`}
                         id="pills-collections"
                         role="tabpanel"
                         aria-labelledby="pills-collections-tab"
                    >

                        <div>
                            <div className="row my-3">
                                <div className="col-lg-3 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3">
                                        <div className="d-flex flex-column gap-1">
                                            <small className="text-muted">{t('totalSalesCrypto')} (crypto)</small>
                                            <h4 className="fw-semibold">{totalSalesMatic}  <small>matics</small></h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3 gap-1">
                                        <div className="d-flex flex-column">
                                            <small className="text-muted">{t('totalSalesCrypto')} (PIX)</small>
                                            <h4 className="fw-semibold">{parseFloat(totalPixPurchases).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3">
                                        <div className="d-flex flex-column gap-1">
                                            <small className="text-muted">{t('totalItemsSold')} (crypto)</small>
                                            <h4 className="fw-semibold">{etherCount}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3">
                                        <div className="d-flex flex-column gap-1">
                                            <small className="text-muted">{t('totalItemsSold')} (PIX)</small>
                                            <h4 className="fw-semibold">{pixCount}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 mb-5">
                                <div className="col lg-6 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3">
                                        <div className="d-flex flex-column gap-1">
                                            <small className="text-muted">{t('amountOwner')}  <a className="small" href={`https://polygonscan.com/address/${data.wallet}`} target="_blank" rel="noopener noreferrer"><i className="bi bi-box-arrow-up-right"></i></a></small>
                                            <h4 className="fw-semibold">{sumCreator}  <small>matics</small></h4>
                                        </div>
                                    </div>
                                </div>                                
                                <div className="col lg-6 mt-3 mt-md-0">
                                    <div className="border rounded-4 p-3">
                                        <div className="d-flex flex-column gap-1">
                                            <small className="text-muted">{t('amountFund')} <a className="small" href="https://polygonscan.com/address/0xD53dA08799d5c201287BC59B1E4Be6a2091f57FB" target="_blank" rel="noopener noreferrer"><i className="bi bi-box-arrow-up-right"></i></a></small>
                                            <h4 className="fw-semibold">{sumEndOfWorld}  <small>matics</small></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'flex',flexDirection:'column'}}>
                                <div style={{display: 'flex', width: '100%', alignItems: 'center', borderRadius: '10px'}}>
                                    <input 
                                        style={{border:'1px solid rgba(0,0,0,.2)',padding: '0.375rem 2.25rem 0.375rem 0.75rem',height: '40px', width:'300px', borderRadius:'5px'}}
                                        type="text" 
                                        placeholder="Buscar..." 
                                        value={filterText} 
                                        onChange={e => setFilterText(e.target.value)}
                                    />
                                </div>
                                {
                                    
                                    <DataTable
                                    columns={imageColumns}
                                    data={filteredItems}
                                    pagination
                                    customStyles={customStyles}
                                    />
                                    
                                }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            {isModalOpen && (
                <ModalCollection isOpen={isModalOpen} location="dashboard" closeModal={handleCloseModal} label="Dados do comprador">
                    <h4 className="fw-semibold m-0">{modalData.name}</h4>
                    <p className='m-0'>{modalData.address} Tipo da carteira: <small>{modalData.googleWallet ? 'Google' : 'Metamask'}</small></p>
                    
                    <hr className='border-secondary' />
                    <ul className='m-0 ps-3'>
                    <li className='pb-1'>CPF: {modalData.cpf}</li>
                    <li className='pb-1'>E-mail: {modalData.email}</li>
                    <li>Telefone: {modalData.phone}</li>
                    </ul>
                </ModalCollection>
                )}

            <ModalDefault
                closeButton={true}
                isOpen={isOpen}
                closeModal={closeModal}
                label={t('share')}
                centered={true}
                size="sm"
            >
                <Share collectionId={collectionId} />
            </ModalDefault>
        </Content>
    );
};
