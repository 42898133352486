import styled from "styled-components";

export const LoadMoreButtonContainer = styled.button`

    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: none;
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background-color: #fff;
        color: #000;
        border: 1px solid #000;
    }


`