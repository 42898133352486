import api from "./api";
import {db} from "../firebase";
import {doc, collection, getDoc, setDoc, Timestamp} from "firebase/firestore";

const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
const creatorsFirestore = process.env.REACT_APP_CREATORS_FB;
const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;


export const compiler = (data) => {
    return api.post('/compiler', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}

export const updateCollection = (data) => {
    return api.post('/updateCollection', data)
    .then(response => response.data)
    .catch(error => console.log(error))
}
export const pinata = (data) => {
    return api.post('/pinata', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const checkNft = async (nft) => {
  const docRef = doc(db, imagesFirestore, nft);
  const docSnap = await getDoc(docRef);
  const collectionData = docSnap.data();  
  if(collectionData.status === 0) {
    return true;
  }
  return false;
  
}

export const updatePinata = (data) => {
    return api.post('/updatePinata', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export const contractUri = (data) => {
    return api.post('/contractUri', data)
        .then(response => response.data)
        .catch(error => console.log(error))
}

export async function checkDuplicateURL(data) {

  const docRef = doc(db, collectionFirestore, data.url);
  const docSnap = await getDoc(docRef);
  const collectionData = docSnap.data();

  return docSnap.exists()

}

export const dataToFirestore = async (data, setCounter,url) => {
  data.status = "blank";
  data.version = "v1";
  data.created_at = new Date();
  data.pixAvailable = false;
  data.wallet = data.wallet.toLowerCase().replace(/\s+/g, '');
  const docref = doc(db, creatorsFirestore, data.wallet);
  const response = await getDoc(docref);

  if (!response.exists()) {
    await setDoc(doc(db, creatorsFirestore, data.wallet), { address: data.wallet });
  }

  const collectionIdRef = url;
  let counter = 1;
  const regex = /#\d+/g;
  const images = [];

  const trash = data.images.map((element, index) => {
    let i = 0;
    let imageData = {};
    const name = element.name.replace(regex, "");
    while (i < element.quantity) {
      imageData = {
        name: name + "#" + counter,
        url: element.url,
        status: 0,
        index: counter,
        nftValue: element.price,
        locked: false
      }
      images.push(imageData);
      i++;
      counter++;
    }
    return element;
  });

  

  data.totalNfts = images.length;
  
  const nfts = [];
  let contador = 1;

  for await (const element of images) {
    const imageId = getId(db);

    setCounter({ id: contador, total: data.totalNfts });
    contador++;

    element.owner = "";
    element.created_at = Timestamp.fromDate(new Date());
    element.collectionId = collectionIdRef;
    element.network = data.network;

    await setDoc(doc(db, imagesFirestore, imageId), element);

    element.imageId = imageId;
    nfts.push({
      imageId: imageId
    });
  }

  delete data.images;
  await setDoc(doc(db, collectionFirestore, url), data);
  

  return {
    id: url,
  };
};






export const getId = () => {
    const collectionRef = collection(db, "collectionName");
    const docRef = doc(collectionRef);
    return docRef.id;
}