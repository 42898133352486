import { validarCPF } from "utils/utils";
import { isValid,parse  } from 'date-fns';
import * as Yup from "yup";

export const formSchema = Yup.object().shape({
    name: Yup.string().required("Preencha este campo"),
    nickname: Yup.string().required("Preencha este campo"),
    email: Yup.string().required("Preencha este campo").email("Insira um e-mail válido"),
    birth_date: Yup.string()
    .required("Preencha este campo")
    .test("validar-data-nascimento", "Data de nascimento inválida", function(value) {
      const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
      return isValid(parsedDate);
    }),
    cpf: Yup.string()
    .required("Preencha este campo")
    .test("validar-cpf", "CPF inválido", (value) => validarCPF(value.replace(/[^\d]+/g, ""))),
    celular: Yup.string().required("Preencha este campo"),
    //description: Yup.string().required(),
});

export const formValues = {
    name: '',
    nickname: '',
    description: '',
    birth_date: '',
    external_link: '',
    email: '',
    celular: '',
    cpf: '',
    site: '',
    twitter: '',
    discord: '',
    instagram: '',
}

export function validateName(value) {
    const result  = parseInt(value.substring(0, 1));
    if(!isNaN(result)) {
        return "Nome da coleção não pode começar com números";
    }
    if(!value.match(/^[a-zA-Z0-9\s]+$/)) {
        return "Nome da coleção não pode conter caracteres especiais"
    }
}