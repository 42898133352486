
import keccak256 from "keccak256"
import { MerkleTree } from "merkletreejs";
import { isWhitelisted } from "./functions"

export const generateHash = (addresses, address) => {
    
    const leaves = addresses.map(x => keccak256(x))
    
    const tree = new MerkleTree(leaves, keccak256, {sortPairs: true})
    
    const buf2hex = x => '0x' + x.toString('hex')
    const leaf = keccak256(address);
    const proof = tree.getProof(leaf).map(x => buf2hex(x.data));
    
    return proof; 
}

export const checkConditions = async (contractForMint, phase, whitelist, wallet) => {

    if(phase === 1) {
        return true;
    }

    if(phase === 2) {
        throw new Error('Contrato não esta disponivel para venda');
    }

    const proof = generateHash(whitelist, wallet);
    const addressWhitelisted = await isWhitelisted(contractForMint, wallet, proof);

    if (!addressWhitelisted) {
        throw new Error('Carteira não disponível para mint');
    }

    return true;

}