import contractJson from "../data/voluhpia.json";
import abiVersion from "../data/abiVersion.json";
import Web3 from "web3";
import Swal from "sweetalert2";

import { ethers } from "ethers";
import keccak256 from "keccak256"
import {MerkleTree} from "merkletreejs";

export const providerReader = (network) => {
    return new ethers.providers.InfuraProvider(
        network,
        "3ac68be0e4f44833bce00d483f3bd104"
    );
}

export const readContract = (provider, address, abi = contractJson.abi ) => {
    return new ethers.Contract(address, abiVersion.abi, provider)
}


export const getTotalSupply = async (contract) => {
    return contract.totalSupply()
        .then((totalSupply) => {
            return totalSupply.toNumber()
        })
        .catch((error) => {
            console.log('error totalSupply', error)
            return 0;
        });
}

export const getMaxSupply = async (contract) => {
    return contract.maxSupply()
        .then((maxSupply) => {
            return maxSupply.toNumber()
        })
        .catch((error) => {
            console.log('error maxSupply', error)
            return 0;
        });
}

export const getPhase = async (contract) => {    
    return contract.phase()
        .then((phase) => {
            return phase.toNumber()
        })
        .catch((error) => {
            throw new Error('errorPhase');
        });
}

export const getNftEtherValue = async (contract) => {
    return contract.getNftEtherValue()
        .then((etherValue) => {
            return ethers.utils.formatEther(etherValue)
        })
        .catch((error) => {
            console.log('error maxSupply', error)
            return 0;
        });
}

export const getConditionPrice = async (contract) => {
    return contract.conditionPrice()
        .then((etherValue) => {
            return ethers.utils.formatEther(etherValue)
        })
        .catch((error) => {
            console.log(error);
            throw new Error('Erro ao capturar preço NFT');
        });
}

export const getName = async (contract) => {
    return contract.name()
        .then((name) => {
            return name
        })
        .catch((error) => {
            console.log('error name', error)
            return 0;
        });
}

export const contractPaused = async (contract) => {
    return contract.paused()
        .then((isPaused) => {
            return isPaused
        })
        .catch((error) => {
            console.log('error paused', error)
            return true;
        });
}


export const switchNetwork = async (provider, chain, setCurrentMessageModal = () => {}, idMessage) => {
    
    if(chain === 137) {
        try {
            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                {
                    chainId: '0x89', // Chain ID da Polygon (137 em decimal)
                    chainName: 'Polygon Mainnet',
                    nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18,
                    },
                    rpcUrls: ['https://polygon-rpc.com/'], // URL do nó RPC da Polygon
                    blockExplorerUrls: ['https://polygonscan.com/'], // URL do explorador de blocos da Polygon
                },
                ],
            });
          } catch (error) {
            return false;
          }
    }

    try {
        setCurrentMessageModal(idMessage) //Mensagem para alterar a rede no modal.
        await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: Web3.utils.toHex(chain)}],
          });
        console.log("You have switched to the right network")

        return new ethers.providers.Web3Provider(provider, "any")

    } catch (e) {
        console.log(e);        
        return false;
    }

};

export const mintNft = async (contract, account, amount, value) => {

    try {
        const tx = await contract.mint(amount, account, {
            from: account,
            value: Web3.utils.toWei(value, 'ether')
        })   
        
        const response = await tx.wait()
        
        return response;
    
    } catch(error) {
        return -1;
    }
    
}   

export const MintCollection = async (contract, account, affiliateLink, amount, etherValue, cid, collectionData, gasPrice, phase) => {

    try {
        let tx;
        if(phase === 0) {

            const proof = generateHash(collectionData.whitelist_addresses, account);
            tx = await contract.mintWhitelist(amount, affiliateLink.wallet, affiliateLink.type, account, proof, cid, {
                from: account,
                value: Web3.utils.toWei(etherValue, 'ether'),
                gasPrice: gasPrice
            })     
        } else {
            tx = await contract.mint(amount, affiliateLink.wallet,affiliateLink.type, account, cid, {
                from: account,
                value: Web3.utils.toWei(etherValue, 'ether'),
                gasPrice: gasPrice
            })
        }
        const response = await tx.wait()

        const txId = response.transactionHash;

        if (txId === undefined) {
            throw new Error('transactionError');
        }        
    
        
        return response;
    
    } catch(error) {
        if (error.code === -1) {
            throw new Error('transactionError');
        }

        if (error.code === -32603) {
            if(!Object.prototype.hasOwnProperty.call(error, "data")) {
                throw new Error("transactionError");
            }
            
            const message = error.data.message.replace("execution reverted: ", "");
            if(message === "insufficient funds for transfer") {
                throw new Error("noBalanceForBuy");
            }
            throw new Error(message);
        }

        if (error.code === 4001) {
            throw new Error('operationcCanceled');
        }

        throw new Error("transactionError");
    }
    
}   

export const SetContractUri = async (contract, ipfs, gasPrice) => {    
    try {
        const tx = await contract.setContractURI(ipfs, {gasPrice: gasPrice})   
        
        const response = await tx.wait()
        
        return response;
    
    } catch(error) {
        console.log(error);
        return error.code;
    }
    
}   

export const isWhitelisted = async(contract, wallet, proof) => {    
    return contract.isWhitelisted(wallet, proof)
        .then((isPaused) => {
            return isPaused
        })
        .catch((error) => {
            console.log('error paused', error)
            return true;
        });    
    
}

export const addTokenMetamask = async (contract, symbol, image,provider) => {

    const tokenDecimals = 0;

    try {
        const wasAdded = await provider.request({
            method: 'wallet_watchAsset',
            params: {
                type: 'ERC20',
                options: {
                    address: contract,
                    symbol: symbol,
                    decimals: tokenDecimals,
                    image: image,
                },
            },
        }).then(function (success) {
            Swal.close();
            
            return true
        }).catch(e => console.log('error', e));

        

        if (wasAdded) {
            provider.on('close', function (accounts) { })
            return true
        } else {
            console.log('wasAdded', wasAdded)
        }
    } catch (error) {

        throw new Error('Erro ao adicionar token a carteira');

        //  Swal.close();
       // swalCustom.fire('Ops!', 'erro ao adicionar token na carteira', 'error');
    }
}

export const generateHash = (addresses, address) => {
    
    const leaves = addresses.map(x => keccak256(x))
    
    const tree = new MerkleTree(leaves, keccak256, {sortPairs: true})
    
    const buf2hex = x => '0x' + x.toString('hex')
    const leaf = keccak256(address);
    const proof = tree.getProof(leaf).map(x => buf2hex(x.data));
    
    return proof; 
}