import React,{Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AuthContextProvider from 'contexts/AuthContext';
import { I18nextProvider } from "react-i18next";
import i18n from './translations/i18n'

import "./assets/css/bootstrap/bootstrap.css";
import "./assets/plugins/fontawesome/css/all.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import 'styles/styles.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
<AuthContextProvider>
    <I18nextProvider i18n={i18n}>
    <Suspense fallback="loading...">
        <App />
    </Suspense>
    </I18nextProvider>
</AuthContextProvider>
);


