import styled from 'styled-components'

export const Content = styled.div`

    padding-bottom: 5rem;

    .banner-collection {
        position: relative;
        display: grid;
        width: 100%;
        height: 400px;
        background: #333;
    }
    
    .banner-collection img {
        width: 100%;
        height: 400px;
        object-fit: cover;
    }
    
    .image-collection {
        bottom: -50px;
        position: absolute;
    }
    
    .image-collection img {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        border: 4px solid #FFFFFF;
    }
        
    .date-format::first-letter {
        text-transform: uppercase;
    }
    
`;

export const SelectWrapper = styled.select`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  width: 130px;

  &:hover {
    background-color: #f5f5f5;
    transition: background-color 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
    border-color: #ee2b7a ;
    transition: border-color 0.2s ease-in-out;
  }
  
  
  

`;

export const Option = styled.option`
  background-color: #f5f5f5;
  color: #333;
`;