import * as S from '../styles'
import { useState } from 'react'
import {useCollection} from "../../../web3/providers/CollectionContext";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import { networkParams } from "web3/networks/networks";
import { InfoCollection } from 'pages/CreateNFT/components/FormCreate/utils';
export const  FirstStepPix = ({changeStep, rfqId}) => {

  const [quantity, setQuantity] = useState(1)
  const {nft} = useCollection();

  function addQuantity(){
    setQuantity(quantity + 1)
  }

  function removeQuantity(){
    if(quantity === 1) return;

    setQuantity(quantity - 1)
  }                

return(
    <S.CheckoutContainer>
       <S.ContentDetails>
        <div>
          <p>{nft?.name}</p>
          <span>{nft?.symbol}</span>
          <Link to={networkParams[nft?.network].address+nft?.contract} target="_blank" >Ver na blockchain</Link>
        </div>
        <S.ImageContainer>
        <img src={nft?.url} alt=""/>
        </S.ImageContainer>
      </S.ContentDetails>

      <strong>Resumo</strong>

      <S.CheckoutDetails>
        <div>
          <p>Quantidade</p>
          <S.QuantityContainer>
            <button disabled onClick={removeQuantity}>-</button>
          <span>{ quantity }</span>
            <button disabled onClick={addQuantity}>+</button>
          </S.QuantityContainer>
        </div>
        <div>
          <p>{nft?.name}</p>
          <span>R$ {rfqId?.quote?.result?.cash?.settlementValue}</span>
        </div>
        <div>
          <p>
            Taxa da rede   
            <Button id="networkFee" type="button" className="link bg-transparent border-0 p-0">
                <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
            </Button>
            <InfoCollection target="networkFee" message="Valor destinado aos mineradores da rede"/></p>
          <span>R$ {rfqId?.quote?.result?.cash?.gas} </span>
        </div>
        <div>
          <p>
            Taxa de conveniência
            <Button id="convenienceFee" type="button" className="link bg-transparent border-0 p-0">
                <i className="fa-sharp fa-solid fa-circle-info text-info ms-2"/>
            </Button>
            <InfoCollection target="convenienceFee" message="É o valor cobrado para realização do processo de mint e entrega do NFT em sua wallet web3."/>
          </p>
          <span>R$ {rfqId?.quote?.result?.processingFee}</span>
        </div>
        <div>
          <p> <strong>Total</strong> </p>
          <span>R$ {rfqId?.quote?.result?.cash?.value}</span>
        </div>
      </S.CheckoutDetails>

      <S.ContinueButton onClick={changeStep}>
        Continuar
      </S.ContinueButton>

    <S.CheckoutFooter>
      <a href='https://itspay.io/'> <strong>Powered by</strong>  <img src="https://itsramp.io/itspay_assets/img/ItsPay.png?t=1683717564" alt="" /></a>
    </S.CheckoutFooter>
    
    </S.CheckoutContainer>
  )

}