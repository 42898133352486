export const HeaderPage = ({label, icon, description}) => {
    return (
        <div className="d-flex gap-4 align-items-center mb-4">
            <div className="bg-header-page shadow-sm rounded-3 p-2 px-3">
                <i className={`${icon} text-primary fs-4`}/>
            </div>

            <div className="d-grid">
                <h5 className="fw-bolder mb-0 text-header">
                    {label}
                </h5>
                <span className="text-muted">{description}</span>
            </div>
        </div>
    )
}