import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {copyAddress} from "../../utils/utils";
import {useApp} from "../../web3/providers/AppContext";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const URL_PROJECT =  process.env.REACT_APP_URL;

export const Share = ({collectionId}) => {
    const { t, i18n } = useTranslation();
    const { collection } = useParams();
    const { wallet } = useApp();
    const collectionLink = (collection !== undefined) ? collection : collectionId;
    const link = URL_PROJECT + collectionLink + "?address=" + wallet;

    return (
       <>
           <div className="copy-area input-group">
               <input type="text" className="form-control form-control-sm" id="input-copy" value={link} disabled />
               <button type="button" className="btn border" onClick={() => copyAddress(link)}>
                   <FontAwesomeIcon
                       icon={faCopy}
                       className="copy-area-icon text-primary"
                   />
               </button>
           </div>

           <div className="promoter-info">
               <h6 className="ff-bold">{t('linkToPromote')}</h6>
               <p className="m-0 small ff-normal">{t('shareText')}</p>
           </div>

           <button
               className="btn btn-secondary rounded-pill "
               onClick={() => copyAddress(link)}
           >
               {t('copyLink')} <FontAwesomeIcon icon={faCopy} />
           </button>
       </>
    )
}