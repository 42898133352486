import styled from 'styled-components';

export const HomeContainer = styled.main`
    @media screen and (max-width: 600px) {
        .container {
            padding-left: 30px;
            padding-right: 30px;  
        } 
        .star-icon {
            left: auto !important;
            right: 30px !important; 
            top: 110px;
        }
    }
    .star-icon {
        left: 500px;
        top: 110px;
    }    
    
    #project,
     #sharing-economy,
      #afm { 
        padding: 130px 0;
   }

   #create-project { 
        padding: 0;
        margin-bottom: 80px;

        .buttons-container{
            @media screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: flex-start;
            }

            
        }
   }
    
    #connect {
        background-color: ${props => props.theme['pink-500']}; 
        color: ${props => props.theme.white}; 
        padding: 3rem 0;
        font-family: 'Figtree Font';
        font-size: 1.5rem;
    }
    
`;

export const HeroContainer = styled.div`
    padding: 80px 0 100px;
    margin-bottom: 200px;
    background: #FFF;
    color: ${props => props.theme.black};
    z-index: 1;
    
    width: 100%;
    height: auto;
    
    h1 {
        font-size: 4rem;
    }
   
   @media screen and (max-width: 576px) {
       h1 {
            font-size: 3rem;
       }
   }
   
    margin-top: -100px;

    align-items: center;
    
    .content-hero img {
         position: absolute;
         bottom: -78px
    }
    
`

const circleColors = {
    pink: '#ee2b7a',
    orange: '#d16c4d',
    green: '#2bb673'
}

export const CircleDecor = styled.span`
    display: flex;
    position: absolute;
    margin-left: -30px;
    margin-top: 7px;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    background: ${props => circleColors[props.color]};
`

export const SubHeroWrapper = styled.section`
    
    @media (max-width: 991px) {
        margin-top: 500px;
}

`