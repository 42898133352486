import { LoadingModal } from 'components/Loading';
import {LoadMoreButtonContainer} from './styles'
import { useState } from 'react';

import {loadMoreNfts} from "services/collection.service"

export function LoadMoreButton({ nfts, setDataImages, collectionData, children, ...restProps }) {    
    const [loading, setLoading] = useState(false);    

    

    const handleLoadMore = async ( ) => {
       
        const page = nfts.length + 1;
        
        const response = await loadMoreNfts(collectionData.id, collectionData, 0, collectionData.symbol, false, page);        
        
        setDataImages([
          ...nfts,
          ...response
        ]);

        
    }



  return <LoadMoreButtonContainer onClick={handleLoadMore}{...restProps}>{ loading && <LoadingModal/>} {children}</LoadMoreButtonContainer>;
}