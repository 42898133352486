import styled from "styled-components";

export const ButtonConnectContainerNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;

    img{
        width: 20px;
        height: 20px;
        margin-top: -5px;
    }
`

export const NavContainer = styled.nav`
    .navbar-toggler.custom-toggler .navbar-toggler-icon {
        color: pink;
    }
`;

export const FlagIcon = styled.img`
    height: 20px;
    width: 30px;
    margin-right: 8px;
    vertical-align: middle;
`;