import { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { DropContainer, UploadMessage } from "./styles";
import React from "react";
import { useTranslation } from 'react-i18next';

export const UploadFiles = ({
                        setFieldValue,
                        setFieldError,
                        setFieldTouched,
                        name,
                        multiple = true
}) => {
    const { t, i18n } = useTranslation();

    const onDrop = useCallback(
        droppedFiles => {
                setFieldValue(name, droppedFiles)
            },
        [],
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, fileRejections, isFocused } = useDropzone({
        onDrop,
        // accept: multiple
        //     ? {
        //           "image/png": [".png", ".jpeg", ".jpg"],
        //           "video/mp4": [".mp4"],
        //       }
        //     :  { "image/png": [".png", ".jpeg", ".jpg"] },
        //maxFiles: limit,
        multiple: multiple,
    });

    useEffect(() => {
        if(fileRejections.length > 0) {
            setFieldError(name, 'Arquivo nao suportado')
            setFieldTouched(name, true, false)
        }
    }, [fileRejections])

    const renderDragMessage = (isDragActive, isDragReject) => {
        if (!isDragActive) {
            return (
                <UploadMessage>
                    <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                    {t('dragDrop')}
                </UploadMessage>
            );
        }
        if (isDragReject) {
            return (
                <UploadMessage type="error">
                    <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                    Arquivo não suportado
                </UploadMessage>
            );
        }
        return (
            <UploadMessage type="success">
                <i className="fa-sharp fa-solid fa-file-arrow-up fs-1 mb-3" />
                Solte os arquivos aqui
            </UploadMessage>
        );
    };

    return (
        <div className="mb-4">
            <div {...getRootProps()} type="file" role="button" aria-label="File Upload" id={name}>
                <input {...getInputProps()} />
                <DropContainer
                    isDragActive={isDragActive}
                    isFocused={isFocused}
                    isDragReject={isDragReject}
                    isDragAccept={isDragAccept}
                >
                    <div className="d-grid align-items-center text-center p-4">
                        {renderDragMessage(isDragActive, isDragReject)}
                    </div>
                </DropContainer>
            </div>
        </div>
    );
};
