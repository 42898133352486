import React, { useState } from "react";

import { CarouselCards } from "./components/CarouselCards";

import { HomeContainer, HeroContainer, SubHeroWrapper } from "./styles";

import Img01 from "assets/img/img-01.png";
import Img02 from "assets/img/img-02.png";
import Img03 from "assets/img/img-03.png";

import { Link } from "react-router-dom";
import { TabTitle, copyAddress } from "../../utils/utils";
import { NewsLetter } from "../../components/NewsLetter";
import { Projects } from "./components/Projects";
import { LastProjects } from "./components/LastProjects";
import { ModalApp } from "components/Modal";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Lottie from "lottie-react";

import SuccessLottie from "../../assets/img/lottie/success.json";
import { useApp } from "web3/providers/AppContext";
import { useTranslation } from 'react-i18next';

export const HomePage = () => {
  TabTitle("Crowd | Projetos que se importam");
  const { register,setRegister } = useApp()
  const { t, i18n } = useTranslation();
  const [modalAberto, setModalAberto] = useState(false); // Modal inicia aberto

  const handleCloseSuccessModal = () =>{
    setRegister(false)
}

  const handleScroll = () => {
    window.scrollTo({
      top: 1350,
      behavior: "smooth",
    });
  };

  return (
    <>
      {modalAberto && (
        <Modal isOpen="true" centered="true" backdrop="true" size="">
          {/* <ModalHeader className="border-0">
                  <p className="fw-bold fs-4 px-3 mb-0">
                      Aviso
                  </p>
          </ModalHeader> */}
          <ModalBody className="py-5 d-grid gap-3 rounded-bottom">
              <div className="text-center">
                <h3 className="fw-bold">Estamos em manutenção</h3>
                <p>Estamos realizando uma manutenção na aplicação. Por favor, volte novamente mais tarde.</p>
                <p className="m-0"><strong>Previsão de retorno:</strong> 25/11/2023</p>
              </div>
          </ModalBody>
      </Modal>
      )}
     <ModalApp label={t('accountCreated')} isOpen={register} closeModal={handleCloseSuccessModal}>
        <>

            <div className="d-flex justify-content-center flex-wrap gap-3 mt-4">
                <p style={{margin:'0',fontSize:'16px',fontFamily:'Figtree Font',textAlign:'center'}}>{t('accountCreatedMsg')}</p>
            <Lottie animationData={SuccessLottie} style={{height: "150px"}} loop={false}/>
                <button
                    className="btn btn-primary w-75"
                    type="button"
                    style={{marginBottom:'20px'}}
                    onClick={handleCloseSuccessModal}
                >
                  {t('continue')}
                </button>
            </div>

        </>
      </ModalApp>

      <HeroContainer>
        <div className="container px-4 px-lg-3">
          <div className="row align-content-between justify-content-center ">
            <div className="col-12 col-lg-5  mt-5 d-grid align-items-center py-auto">
              <div className="pt-5">
                <h1 className="ff-light d-grid mt-5 lh-1">
                  {t('hero')} <br /> {t('hero2')} <br /> {t('hero3')}
                  <br />
                  <span className="ff-medium">
                  {t('hero4')} <br /> {t('hero5')}
                  </span>
                </h1>

                <div className="pb-5 mt-5">
                  <Link
                    to="/our-project"
                    className="btn btn-primary rounded-pill btn-lg px-5 py-2 ff-normal"
                  >
                    {t("projects")}
                  </Link>
                </div>
              </div>

            </div>

            <div className="col-12 col-lg-7 ">
              <CarouselCards />
            </div>
          </div>
        </div>
      </HeroContainer>

      <HomeContainer>
        <SubHeroWrapper>
          <div className="container ">
            <div className="row align-items-center">
              <div className="col-12 col-lg-5 position-relative float-end">
                <div
                  className="d-flex justify-content-end mb-3 position-absolute star-icon"
                 
                >
                  <img src={Img01} alt="" />
                </div>
                <h3 className="fs-1 ff-light text-start d-grid">
                  <span>{t('subHero')}</span>
                  <span>{t('subHero2')}</span>
                  <span>{t('subHero3')}</span>
                  <span>{t('subHero4')}</span>
                  <span>{t('subHero5')}</span>
                  <span className="ff-bold">{t('subHero6')}</span>
                  <span className="ff-bold">{t('subHero7')}</span>
                </h3>
              </div>

              <div className="col-1 ms-5 d-none d-lg-block"></div>

              <div className="col-12 col-lg-5 mt-5 mt-lg-0">
                <p className="text-custom">
                  {t('homeP1')}
                </p>
                <p className="text-custom">
                {t('homeP2')}
                </p>
              </div>
            </div>
          </div>
        </SubHeroWrapper>

        <section id="project">
          <LastProjects />
          <Projects />
        </section>

        <section id="sharing-economy">
          <div className="container">
            <h2 className="label-custom">Share and Earn</h2>
            <div className="row">
              <div className="col-12 col-lg-5">
                <p className="text-custom">
                {t('homeP3')}
                </p>
                <p className="text-custom">
                {t('homeP4')}
                </p>
              </div>
              <div className="col-12 col-lg-4">
                <div className="d-flex justify-content-center">
                  <img src={Img03} alt="" />
                </div>
              </div>
            </div>

            <div className="pt-5">
              <button
                className="btn btn-lg btn-secondary rounded-pill px-5 py-3 ff-medium"
                onClick={handleScroll}
              >
                {t('generateLink')}
              </button>
            </div>
          </div>
        </section>

        <section id="afm">
          <div className="container">
            <h2 className="label-custom" style={{ lineHeight: "35px" }}>
              {" "}
              {t('fundToEnd')}
            </h2>

            <div className="row">
              <div className="col-12 col-lg-7">
                <p className="text-custom mb-0">
                {t('homeP5')}
                </p>
                <div className="d-flex flex-wrap gap-3 py-5">
                  <Link to="fund">
                    <button className="btn btn-lg btn-secondary rounded-pill px-5 py-3 ff-medium">
                    {t('readMore')}
                    </button>
                  </Link>
                  <Link to="request">
                    <button className="btn btn-lg btn-secondary rounded-pill px-5 py-3 ff-medium">
                    {t('resources')}
                    </button>
                  </Link>
                  <a target="_blank" href='https://polygonscan.com/address/0xD53dA08799d5c201287BC59B1E4Be6a2091f57FB'>
                    <button className="btn btn-lg btn-secondary rounded-pill px-5 py-3 ff-medium">
                    {t('fundBalance')}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="create-project" class="position-relative">
          <div className="container">
            <h2 className="label-custom"> {t('inspireTitle')}</h2>

            <div className="row">
              <div className="col-12 col-lg-9">
                <p className="text-custom mb-4" style={{maxWidth:'560px'}}>
                {t('homeP6')}
                </p>
                <p className="text-custom" style={{maxWidth:'560px'}}>
                {t('homeP7')}
                </p>
                <div
                  style={{ display: "flex", gap: "10px", marginTop: "40px" }}
                  className="buttons-container"
                >
                  <Link
                    to="/create"
                    className="btn btn-lg btn-secondary rounded-pill px-3 py-3 ff-medium"
                  >
                      {t('createProject')}
                  </Link>
                  <a
                    href="https://www.youtube.com/watch?v=S8ftgmMPZhs&t=14s"
                    target="_blank"
                    className="btn btn-lg btn-secondary rounded-pill px-3 py-3 ff-medium"
                  >
                       {t('howToMetamask')}
                  </a>
                  <a
                    href="https://www.youtube.com/watch?v=upMlytsWUZE"
                    target="_blank"
                    className="btn btn-lg btn-secondary rounded-pill px-3 py-3 ff-medium"
                  >
                       {t('howToMatic')}
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-3">
                <div className="heart-icon d-flex ">
                  <img src={Img02} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <NewsLetter />

        <section id="connect">
          <div className="container">
            <div className="row justify-content-center gx-">
              <div className="col-12 col-md-5 col-xl-3">
                <p
                  className="ff-normal fs-5 mb-4"
                  style={{ marginLeft: "45px" }}
                >
                     {t('iAmDesigner')}
                  <br /> {t('iAmDesigner2')}
                  <br /> {t('iAmDesigner3')}
                </p>
                <button className="btn btn-lg bg-white rounded-pill py-3 px-5">
                {t('iAmDesigner4')}
                </button>
              </div>

              <div className="col-12 col-md-5 col-xl-3 offset-lg-1 mt-5 mt-md-0">
                <p
                  style={{ marginLeft: "45px" }}
                  className="ff-normal fs-5 mb-4"
                >
                  {t('iHaveProject')} <br /> {t('iHaveProject2')}
                  <br /> designer.
                </p>
                <button className="btn btn-lg bg-white rounded-pill py-3 px-5">
                {t('iHaveProject3')}
                </button>
              </div>
            </div>
          </div>
        </section>
      </HomeContainer>
    </>
  );
};
