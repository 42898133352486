import {useAuth} from "admin/contexts/Auth/AuthProvider";
import logo from 'assets/img/logo-crowd.png';
import {useEffect, useState} from "react";
import {Toast} from "../../components/Alert";
import {useNavigate} from "react-router-dom";
import { Formik, Field, Form } from 'formik';


export const LoginForm = () => {

    const { login, user } = useAuth()

    const navigate = useNavigate()

    useEffect(() => {
        if(user) {
            navigate('/admin')
        }
    },[])

    const auth = (values) => {
        login(values).then(response => {
            if (!response) {
                Toast.fire({
                    icon: 'error',
                    title: 'Dados de login inválidos'
                })
                return
            }
            navigate('/admin')
        })
    }

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    return (
        <div className="card rounded-4">
            <div className="card-body bg-dark rounded-4">
                <div className="col-md-12 d-flex flex-center">
                    <div className="p-3 p-md-5 flex-grow-1">
                        <div className="row flex-between-center mb-4">
                            <div className="col-auto m-auto mb-3">
                                <img src={logo} alt="logo" width={180} />
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={(values,{ setSubmitting }) => {
                                auth(values)
                                setSubmitting(false)
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form>

                                    <div className="form-floating mb-3">
                                        <Field type="email" name="email" className="form-control" id="email"
                                               placeholder="name@example.com" required/>
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="form-floating">
                                        <Field type="password" name="password" className="form-control" id="password"
                                               placeholder="**********" required/>
                                        <label htmlFor="Senha">Senha</label>
                                    </div>

                                    <div className="my-4">
                                        <button
                                            className="btn btn-primary btn-lg d-block w-100 mt-3"
                                            type="submit"
                                            name="submit"
                                            disabled={isSubmitting}
                                        >
                                            { isSubmitting
                                                ? <div className="spinner-border spinner-border-sm" role="status">
                                                    <span className="visually-hidden">Aguarde...</span>
                                                </div>
                                                : 'Acessar'
                                            }

                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}