import {Link, NavLink} from "react-router-dom";

import logo from 'assets/img/logo-crowd.png';
import logo_mini from 'assets/img/logo-mini.png';

export const SidebarAdmin = ({ open }) => {
    return (
        <div className={`l-navbar shadow-sm ${open && 'show'}`} id="nav-bar">
            <nav className="nav">
                <div className="nav-brand">
                    <Link to="dashboard" className="nav_logo">
                        {open
                            ?
                            <>
                                <img src={logo_mini} alt="logo" className="d-block d-md-none" width={40}  />
                                <img src={logo}  alt="logo" className="d-none d-md-block m-auto" width={120} />
                            </>
                            : <img src={logo_mini} alt="logo" width={40}  /> }
                    </Link>
                </div>
                <div>
                    <div className="nav_list">
                        <NavLink to="dashboard" className="nav_link">
                            <i className="bi bi-house nav_icon"/>
                            <span className="nav_name">Acesso rápido</span>
                        </NavLink>
                        <NavLink to="collections" className="nav_link">
                            <i className="bi bi-files nav_icon"/>
                            <span className="nav_name">Coleções</span>
                        </NavLink>

                        <NavLink to="categories" className="nav_link">
                            <i className="bi bi-tags nav_icon"/>
                            <span className="nav_name">Categorias</span>
                        </NavLink>

                        <NavLink to="users" className="nav_link">
                            <i className="bi bi-people nav_icon"/>
                            <span className="nav_name">Usuários</span>
                        </NavLink>
                        <NavLink to="sales" className="nav_link">
                            <i className="bi bi-cash-coin nav_icon"/>
                            <span className="nav_name">Vendas</span>
                        </NavLink>
                    </div>
                </div>
            </nav>
        </div>
    )
}