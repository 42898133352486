import { Content } from "./styles";
import { CardNFT } from "../CardNFT";

export const ListNFT = ({ nfts,isProfile }) => {
    return (
        <Content>
            <div className="row g-4">
                <div className="col-12">
                    <div className="row g-4">
                        {nfts?.map(nft => {
                            if (nft) {
                                return (
                                    <div key={"container-card-nft" + (nft.imageId ? nft.imageId : nft.id)} className="col-12 col-sm-6 col-md-4 col-xl-3">
                                        <CardNFT nft={nft} isProfile />
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </div>
        </Content>
    );
};
