import styled from 'styled-components';

export const CollectionDetailContainer = styled.div`
  .current-owner img {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    object-fit: cover;
  }

  a{
    text-decoration: none;
  }
`;