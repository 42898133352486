import {useEffect, useState} from "react";

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import { PlaceholderCards } from "components/Placeholder";
import { CardCreator } from "./components/CardCreator";

import { listCreators } from "services/creator.service";

export const CreatorsPage = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const request = async () => {
            setLoading(true)
            const response = await listCreators();
            setData(response.data)
        }
        request().then( () => setLoading(false) )
    }, [])

    return (
        <>
            <div className="container marketplace py-5">

                <h1 className="fs-1 fw-bolder">Criadores</h1>

                <div className="row justify-content-between mt-5">
                    <div className="col-12 col-xl-3">
                        <div className="input-group ">
                            <input type="search" className="form-control bg-dark text-white border-0 rounded-0"
                                   placeholder="Buscar"
                                   aria-label="Search" aria-describedby="Search"/>
                            <span className="input-group-text bg-transparent input-search-icon border-0"
                                  id="basic-addon2">
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary"/>
                            </span>
                        </div>
                    </div>
                </div>

                <hr className="my-4"/>

                <div className="row mt-4">
                    <div className="col-12  mt-4">
                        <div className="row mb-5 g-4">
                            { loading && <PlaceholderCards totalCards={4} /> }

                            { !loading && data?.map((creator, index) =>
                                creator?.collections?.length > 0 && (
                                    <div key={index} className="col-12 col-md-6 col-xl-3">
                                        <CardCreator creator={creator} />
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
