import logo from '../../assets/img/logo-crowd.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faInstagram,
    faTwitter,
    faDiscord
} from '@fortawesome/free-brands-svg-icons';

import {Link} from "react-router-dom";
import {FooterContainer} from "./styles";
import {HashLink} from "react-router-hash-link";
import { useTranslation } from 'react-i18next';

const SocialLink = (props) => {
    return (
        <Link to="#">
            <div className="social-link">
                <FontAwesomeIcon icon={ props.icon } />
            </div>
        </Link>
    )
}

const LinkFooter = (props) => {
    return (
        <li className="nav-item mb-2">
            <Link to={props?.to} className="nav-link p-0 ff-normal">{ props.label }</Link>
        </li>
    )
}


const Footer = () => {
    const { t, i18n } = useTranslation();
    return (
        <FooterContainer className="footer">
            <div className="container">

                <div className="row row-footer gx-5 gy-5" style={{display:'flex',alignItems:'center'}}>

                    <div className="col-12 col-md-12 col-lg-3 mb-3">
                        <Link to="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none justify-content-center justify-content-xl-start">
                            <img src={logo} alt="Logo" width={200} />
                        </Link>
                    </div>

                    <div className="col-12 col-lg-2 mb-3">
                        <div style={{display:'flex',alignItems:'center',gap:'10px',justifyContent:'space-between',width:'400px'}}>
                          
                                <HashLink
                                    smooth
                                    to={'/#project'}
                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start'})}
                                    className="nav-link p-0 ff-normal"
                                >
                                   {t('projectsNav')}
                                </HashLink>
                                <HashLink
                                    smooth
                                    to={'/#sharing-economy'}
                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start'})}
                                    className="nav-link p-0 ff-normal"
                                >
                                   share and earn
                                </HashLink>
                                <HashLink
                                    smooth
                                    to={'/#afm'}
                                    scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'start'})}
                                    className="nav-link p-0 ff-normal"
                                >
                                    {t('fundAfm')}
                                </HashLink>

                                <HashLink
                                to={'/#create-project'}
                                smooth
                                scroll={(el) => el.scrollIntoView({ behavior: 'auto', block: 'center' })}
                                className="nav-link p-0 ff-normal"
                            >
                               {t('createYourProject')}
                            </HashLink>
                           
                        </div>
                    </div>
                    <div className="col-12 col-lg-5 offset-lg-2 text-white">
                        <div className="d-flex flex-wrap flex-lg-nowrap text-center justify-content-center justify-content-lg-end gap-3 text-md-end">

                         

                            <span className="ff-normal lh-2">{t('howToImprove')}<br />
                                <span>info@crowdproject.io</span>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </FooterContainer>
    )
}

export default Footer
