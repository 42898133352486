import { useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs, query, where, orderBy } from "firebase/firestore";
import { db } from "../../../firebase";
import DataTable from "react-data-table-component";
import { HeaderPage } from "admin/components/HeaderPage";
import { ModalAdmin } from '../../components/Modal';


export const SalesAdmin = () => {
    const collectionFirestore = process.env.REACT_APP_COLLECTION_FB;
    const creatorsFirestore =  process.env.REACT_APP_CREATORS_FB; 
    const urlMarketplace = process.env.REACT_APP_URL;
    const imagesFirestore =  process.env.REACT_APP_IMAGES_FB;
    const rfqFirestore = process.env.REACT_APP_RFQS_FB;
    const [images, setImages] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [isLoading,setIsloading] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({})
  
    const infoOwner = (creatorName, address, email, phone, googleWallet = false, cpf, nft, collection, purchased, pag) => {
      setModalOpen(true);
      setModalData({
        name: creatorName,
        address: address,
        email: email,
        phone: phone,
        googleWallet: googleWallet,
        cpf: cpf,
        nft: nft,
        collection: collection,
        purchased: purchased,
        pag: pag
      })
    }
  
    const handleCloseModal = () => {
      setModalOpen(false);
    }

    const fetchImages = async () => {
        setIsloading(true)
        const imagesQuery = query(collection(db, imagesFirestore), where("status", "==", 1), orderBy('purchaseAt', 'desc'));

        const fetchedImages = [];
        try{
          const querySnapshot = await getDocs(imagesQuery);
          for (const imageDoc of querySnapshot.docs) {
              let imageData = imageDoc.data();
              const purchaseAt = new Date(imageData.purchaseAt.seconds * 1000);

              // Converta os nanossegundos em milissegundos e adicione à data
              purchaseAt.setMilliseconds(purchaseAt.getMilliseconds() + Math.round(imageData.purchaseAt.nanoseconds / 1000000));

              const day = purchaseAt.getDate().toString().padStart(2, '0');
              const month = (purchaseAt.getMonth() + 1).toString().padStart(2, '0');
              const year = purchaseAt.getFullYear();
              const hours = purchaseAt.getHours().toString().padStart(2, '0');
              const minutes = purchaseAt.getMinutes().toString().padStart(2, '0');
        
              imageData.purchased = `${day}/${month}/${year} ${hours}:${minutes}`;

              const documentoRef = doc(db, creatorsFirestore, imageData.owner);
              const documentoSnapshot = await getDoc(documentoRef);
              imageData.CreatorName = imageData.owner;
              if (documentoSnapshot.exists()) {
                  const dadosDoDocumento = documentoSnapshot.data();
                  imageData.CreatorName   = dadosDoDocumento.name;
                  imageData.CreatorWallet = dadosDoDocumento.address;
                  imageData.CreatorEmail = dadosDoDocumento.email;
                  imageData.CreatorPhone = dadosDoDocumento.celular;
                  imageData.CreatorGoogleWallet = dadosDoDocumento.isGoogleWallet;
                  imageData.CreatorCpf = dadosDoDocumento.cpf;
                  imageData.CreatorNickname = dadosDoDocumento.nickname;
              } 

              const colecaoRef = doc(db, collectionFirestore, imageData.collectionId);
              const colecaoSnapshot = await getDoc(colecaoRef);
              imageData.collectionName = imageData.collectionId;
              if (colecaoSnapshot.exists()) {
                  const dadosDoDocumento = colecaoSnapshot.data();
                  imageData.collectionName = dadosDoDocumento.name
              }

              if (imageData.pix && imageData.rfqId) {
                  const rfqDoc = await getDoc(doc(db,rfqFirestore, imageData.rfqId));
                  if (rfqDoc.exists()) {
                      const rfqData = rfqDoc.data();
                      if (rfqData && rfqData.rfq && rfqData.rfq.order && rfqData.rfq.order.quote && rfqData.rfq.order.quote.result && rfqData.rfq.order.quote.result.cash && rfqData.rfq.order.quote.result.cash.value) {
                          imageData.realValue = rfqData.rfq.order.quote.result.cash.value;
                      }
                  }
              }
              setIsloading(false)
              fetchedImages.push(imageData);
          }  
          setImages(fetchedImages);
        } catch (error) {
            console.error('Erro ao obter os últimos documentos:', error);
        }
    };

    useEffect(() => {
        fetchImages();
    }, []);

    // const filteredItems = images.filter(item => 
    //     item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    // );

    const filteredItems = images.filter(item => {
      const search = filterText.toLowerCase();
        return (
            (item.name && item.name.toLowerCase().includes(search)) ||
            (item.collectionName && item.collectionName.toLowerCase().includes(search)) ||
            (item.CreatorName && item.CreatorName.toLowerCase().includes(search))
        );
    });

    const imageColumns = [
        {
            name: '#',
            cell: (row, index) => {
                return index + 1;
            },
            sortable: true,
            width: 'fit-content'
        },
        {
            name: 'Item',
            selector: 'name',
            sortable: true
        },
        {
            name: 'Projeto',
            cell: row => (
                <>
                  {row.collectionName}{' '}
                  <small className="ms-1">
                      <a className="small" href={`${urlMarketplace}${row.collectionId}`} target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-box-arrow-up-right"></i>
                      </a>
                  </small>
                </>
            ),
            sortable: true
        },
        {
            name: 'Pagamento',
            selector: img => img.pix ? 'Pix' : 'Crypto',
            sortable: true
        },
        {
            name: 'Valor',
            selector: img => {
                if (img.pix && img.realValue) {
                    return parseFloat(img.realValue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                } else if (parseInt(img.network) === 1 || parseInt(img.network) === 5) {
                    return `${img.nftValue} Ether`;
                } else if (parseInt(img.network) === 80001 || parseInt(img.network) === 137) {
                    return `${img.nftValue} MATIC`;
                } else {
                    return img.nftValue;
                }
            },
            sortable: true
        },
        {
            name: 'Comprado por',
            selector: 'CreatorName',
            cell: row => (
              <>
              <strong className='cursor-pointer' onClick={() => infoOwner(row.CreatorName, row.CreatorWallet, row.CreatorEmail, row.CreatorPhone, row.CreatorGoogleWallet, row.CreatorCpf, row.name, row.collectionName, row.purchased, row.pix)}>{!row.CreatorName ? (row.CreatorNickname) : (row.CreatorName)}</strong>
              </>
            ),
            sortable: true
        },
        {
            name: 'Carteira',
            selector: 'CreatorWallet',
            sortable: true,
            cell: row => {
                const wallet = row.CreatorWallet;
                if (wallet.length >= 8) {
                    const firstFour = wallet.substr(0, 5);
                    const lastFour = wallet.substr(wallet.length - 4);
                    return `${firstFour}...${lastFour}`;
                }
                return wallet; // Retorna a carteira completa se tiver menos de 8 caracteres
            }
        },
        {
          name: 'Data',
          selector: 'purchased',
          sortable: true
        },
    ];

    const customStyles = {
        table: {
          style: {
            padding: '20px',
            backgroundColor: '#30353f',
            borderRadius: '8px',
            overflow: 'hidden',
          },
        },
        headCells: {
          style: {
            fontSize: '1rem',
            backgroundColor: '#30353f',
            color: 'white',
            padding: '10px 18px',
            fontWeight:'bold',
            fontSize:'16px'
          },
        },
        rows: {
          style: {
            fontSize: '16px',
            backgroundColor: '#30353f',
            borderColor: '#333',
            color: 'white',
            padding: '10px 0',
          },
          hoverStyle: {},
        },
        cells: {
          style: {
            backgroundColor: '#30353f',
            color: 'white',
            padding: '10px 20px',
          },
        },
        pagination: {
          style: {
            backgroundColor: '#30353f',
            color: '#ffffff !important',
            padding: '20px',
            borderRadius: '10px',
            marginTop: '10px',
          },
          pageButtonsStyle: {
            color: '#ffffff',
            borderColor: '#ffffff',
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            hoverBackgroundColor: '#ffffff',
            hoverBorderColor: '#ffffff',
            activeBackgroundColor: 'transparent',
            activeBorderColor: 'transparent',
            marginRight: '5px',
            marginLeft: '5px',
          },
          hoverStyle: {}
        },
      };

    return (
      <div className="container-fluid">
        <HeaderPage
                label="Vendas"
                icon="bi bi-cash-coin"
                description=""
            />
        <div style={{display:'flex',flexDirection:'column'}}>
          <div style={{display: 'flex', width: '100%', background: '#30353f', alignItems: 'center', marginBottom: '20px', borderRadius: '10px'}} className="p-3">
                 <input 
                style={{backgroundColor:'#30353f',border:'1px solid white',padding: '0.375rem 2.25rem 0.375rem 0.75rem',width:'300px',borderRadius:'5px',color:'white'}}
                type="text" 
                placeholder="Buscar por nome do projeto..." 
                value={filterText} 
                onChange={e => setFilterText(e.target.value)}
            />
          </div>
            {
                isLoading ? (
               
                 <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}>  
                <div className="spinner-border text-primary" style={{height:'60px',width:'60px'}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
                </div>
                   
              
                ):
                (
                <DataTable
                columns={imageColumns}
                data={filteredItems}
                pagination
                customStyles={customStyles}
                />
                  )
            }
        </div>
        {isModalOpen && (
          <ModalAdmin isOpen={isModalOpen} location="dashboard" closeModal={handleCloseModal} label="Dados do comprador">
            <h4 className="fw-semibold m-0">{modalData.name}</h4>
            <p className='m-0'>{modalData.address} <small>{modalData.googleWallet ? 'Google' : 'Metamask'}</small></p>
            <p className='m-0 text-underline'>Comprou o item <strong>{modalData.nft}</strong> da coleção <strong>{modalData.collection}</strong> pagando com <strong>{modalData.pag ? 'PIX' : 'crypto'}</strong> em {modalData.purchased}.</p>
            <hr className='border-secondary' />
            <ul className='m-0 ps-3'>
              <li className='pb-1'>CPF: {modalData.cpf}</li>
              <li className='pb-1'>E-mail: {modalData.email}</li>
              <li>Telefone: {modalData.phone}</li>
            </ul>
          </ModalAdmin>
        )}
      </div>
    )
}
