import {useEffect, useState } from "react";

import {faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { TabTitle } from "../../utils/utils";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getCollections} from "../../services/collection.service";

import {useApp} from "../../web3/providers/AppContext";
import ListCollection from 'components/ListCollection/ListCollection';
import { useTranslation } from 'react-i18next';
import {FilterCategories} from "../../components/FilterCategories";


export const MarketplacePage = () => {
    TabTitle("Crowd | Nossos projetos");

    const [collections, setCollections] = useState([])
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation();
    const [filterCategory, setFilterCategory] = useState('all')
    const [queryFilter, setQueryFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const { layoutHeader } = useApp()

    useEffect(() => {
        layoutHeader(2)
    }, [layoutHeader])


    useEffect(() => {
        const request = async () => {
            setLoading(true)
            const response = await getCollections(filterCategory);
            setCollections(response)
        }
        request().then( () => setLoading(false) )
    }, [filterCategory, queryFilter])

    function handleFilter(value) {
        setFilterCategory(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            doSearch();
        }
      };

    const doSearch = async () => {
    setLoading(true)
    const response = await getCollections('', searchTerm);
    setCollections(response)
    
    };
    

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
      };

    return (
        <>
            <div className="container marketplace py-5 mb-5">
                <h1 className="fs-1 fw-bolder">{t('ourProjects')}</h1>

                <div className="row justify-content-between mt-5" >
                    { /*
                    <div className="col-12 col-xl-3">
                        <div className="input-group ">
                            <input type="text" className="form-control rounded-pill"
                                   placeholder="Buscar"
                                   aria-label="Search" aria-describedby="Search"
                                   value={searchTerm}
                                   onChange={handleChange}
                                   onKeyDown={handleKeyDown}

                            />
                            <span className="input-group-text bg-transparent input-search-icon border-0"
                                  id="basic-addon2">
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="text-primary"/>
                        </span>
                        </div>

                    </div>
    */}
                    <div className="col-12 col-xl-9">
                        <FilterCategories handleFilter={handleFilter} filter={filterCategory} />
                    </div>
                </div>

                <hr className="mt-4"/>

                <div className="row mt-4">
                    <ListCollection loading={loading} collections={collections} />
                </div>
            </div>
        </>
    )
}
