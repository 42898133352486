import styled from 'styled-components';


export const FooterContainer = styled.footer`
    width: 100%;
    padding: 6rem 0 4rem;
    
    background-color: ${props => props.theme.black};
    
    .row-footer .border-divider {
      border-right: 1px solid #d6d6d6;
      border-left: 1px solid #d6d6d6;
    }
    
    @media (max-width: 1199px) {
        .row-footer .border-divider {
            border: none;
        }
    }
    
    .row-footer h5 {
        margin-bottom: 10px;
    }
    
    .nav-link {
        color: #FFF;
    }
    
    footer .nav.flex-column > li .nav-link {
      padding: 7px 0px;
      color: #d6d6d6;
      position: relative;
      font-weight: 400;
    }    
    
`