import useDebounce from "admin/hooks/useDebounce";
import {useState} from "react";

export const FilterStatusCollection = ({onChange}) => {
    return (
        <select className="form-select" onChange={ (e) => onChange(e.target.value)}>
            <option value="all">Filtrar por status</option>
            <option value="blank">Não finalizado</option>
            <option value="submitted">Aguardando Aprovação</option>
            <option value="approved">Aprovado</option>
            <option value="denied">Negado</option>
        </select>
    )
}

export const FilterQuery = ({onChange}) => {

        const [value, setValue] = useState('')

        const apply = () => {
            onChange(value)
            setValue('')
        }

        return (
            <div className="input-group">
                <input
                    placeholder="Pesquise por nome"
                    className="form-control"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <button className="btn btn-primary" onClick={apply} >Aplicar</button>
            </div>
    )
}

export const FilterStatusUser = ({onChange}) => {
    return (
        <select className="form-select" onChange={ (e) => onChange(e.target.value)}>
            <option value="">Filtrar por status</option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
        </select>
    )
}