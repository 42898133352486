import styled from "styled-components";

export const ConnectSection = styled.section`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  padding-left: 50vw;
  padding-right: 50vw;
  margin-bottom: -90px;

  @media screen and (max-width: 920px) {
    margin-bottom: -80px;
  }

  background-color: ${(props) => props.theme["pink-500"]};
  color: ${(props) => props.theme.white};
  padding: 3rem 0;
  font-family: "Figtree Font";
  font-size: 1.5rem;
`;

export const FundPageContainer = styled.div`
  max-width: 1340px;
  margin: 90px auto;
  font-family: "Figtree Font";

  @media screen and (max-width: 1399px) {
    max-width: 1140px;
  }

  @media screen and (max-width: 1198px) {
    max-width: 940px;
  }


  @media screen and (max-width: 920px) {
    margin: 40px;
  }

  h4 {
    display: flex;
    gap: 10px;
    font-weight: bold;
  }

  .flower-container {
    display: flex;
    height: 30px;
    width: 30px;
  }

  @media screen and (max-width: 600px) {
    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  margin-bottom: 220px;

  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }

  h3 {
    font-weight: bold;
    font-size: 3.5rem;
  }

  p {
    padding: 0;
  }
`;

export const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 30px ;

  p {
    font-size: 1.3rem;
  }

  strong {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 920px) {
    margin-bottom: 40px;
  }
`;

export const ImagesProfileContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  width: 140px;
  height: 140px;

  p {
    text-align: center;
    font-weight: bold;
  }
`;

export const GridImages = styled.div`
  display: grid;
  margin-top: -45px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 30px;
  margin-right: 60px;

  strong {
    grid-column: 1 / -1;
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    margin-top: 20px;
  }

  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);

    strong {
      grid-column: 1 / -1;
      text-align: center;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }
`;
