import styled from 'styled-components'

export const CategoriesContainer = styled.div`
    display: flex;
    gap: 20px;
    
    @media (max-width: 452px) {
        gap: 0px;
    }
    
    .btn-check-custom.active-tab {
        border-color: transparent;
        border-radius: 0;
        border-bottom: 3px solid ${props => props.theme["pink-500"]};
    }
`