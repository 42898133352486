import {useCollection} from "../../web3/providers/CollectionContext";
import {calcPriceBrlNft} from "../../utils/utils";
import {useApp} from "../../web3/providers/AppContext";
import Lottie from "lottie-react";

import {LoadingModal} from "../Loading";
import ModalDefault from "../ModalDefault"
import React from "react";
import { useState } from "react";
import {Link} from "react-router-dom";

import SuccessLottie from "../../assets/img/lottie/success.json";
import ErrorLottie from "../../assets/img/lottie/error.json";
import EthereumImg from "assets/img/svg/ethereum.svg";
import PolygonImg from "assets/img/svg/polygon.svg";
import { CheckoutPix } from "components/Checkout";
import useAuth from "hooks/useAuth";
import { networkParams } from "web3/networks/networks";
import { useTranslation } from 'react-i18next';



export const ProcessMint = ({mint, handleModal, currentStepModal, currentMessageModal, handleAddTokenToMetamask, messageError, txId,setIsOpenToBuy,setModal}) => {
    const {wallet, connectWallet} = useApp()
    const { t, i18n } = useTranslation();
    const {googleAccount} = useAuth()

    const stepsMessage = [
        t('mint.wait'),
        t('mint.changeChain'),
        t('privateKey.waiting'),
        t('mint.requestFail'),
        t('mint.tokenAvailable'),
        t('mint.errorAddingToken'),
        t('mint.tokenAdded')
    ]

    const {nft, collectionData, crypto} = useCollection();
    const { nftEtherValue } = collectionData;

    const amount = 1
    const priceBRL = calcPriceBrlNft(amount, crypto, nftEtherValue)

    const [showCheckout, setShowCheckout] = useState(false);
    const [actuallyLabel, setActuallyLabel] = useState( t('buy') + 'Nft')


    function handleOpenCheckout(){
        setShowCheckout(true)
    }

    function handleCloseCheckout(){
        setModal(false)
        setShowCheckout(false)
    }

    const handleClose = () => {
        handleModal()
        setIsOpenToBuy(true)
    }

    return (
        <>
            {!wallet ?
                <div className="px-3">
                    <p className="ff-medium">{t('mint.connectToProceed')}</p>

                    <div className="d-flex gap-3">
                        <button className="btn btn-secondary w-50" type="button" onClick={() => handleModal()}>
                            {t('mint.privateKey.closeButton')}
                        </button>
                        <button className="btn btn-primary w-50" type="button" onClick={connectWallet}>
                        {t('mint.connectOrCreate')}
                        </button>
                    </div>
                </div>
                :
                <>
                    { currentStepModal === 0 ?
                        <>
                            <div className="card border-0 shadow p-3">
                                <div className="d-flex justify-content-between align-items-center">

                                    <div className="d-flex align-items-center gap-3">
                                        <img src={nft.url} className="rounded-3" alt="Imagem do NFT" width={60} height={60}
                                             style={{objectFit: "cover"}}/>

                                        <h6 className="mb-0 ff-bold">{nft.name}</h6>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <div className="d-grid">
                                        <span className="ff-bold text-end fs-5">
                                            {nftEtherValue} {crypto}
                                        </span>
                                            <span className="text-muted text-end small">{priceBRL}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <span className="border-top mt-3" />

                            <span>{t('mint.formsPay')}</span>

                            <div style={{display:'flex',width:'100%',gap:'20px'}}>
                                
                                <div style={{width:'50%'}}>
                                    {googleAccount && <label style={{fontSize:'10px',marginBottom:'8px'}}>{t('mint.onlyMetamask')}</label>}
                                <button disabled={googleAccount} className="btn btn-secondary btn-lg" style={{width:'100%'}}  type="button" onClick={mint}>
                                    <img
                                        src={ crypto === 'ETH' ? EthereumImg : PolygonImg }
                                        alt=""
                                        width={25}
                                        className="me-2"
                                        style={{marginTop: "-4px"}}
                                    />
                                    {crypto}
                                </button>
                                </div>

                                <button onClick={handleOpenCheckout} disabled={!collectionData.pixAvailable} className="btn btn-secondary btn-lg" type="button" style={{width:'50%',height:'48px',alignSelf:'end'}}>
                                    <i className="fa-brands fa-pix me-2"/>PIX
                                </button>
                            </div>
                            <ModalDefault isOpen={showCheckout} closeModal={handleCloseCheckout} label={actuallyLabel} closeButton={handleCloseCheckout}  >
                               <CheckoutPix isOpen={showCheckout} closeModal={handleCloseCheckout} modalLabel={setActuallyLabel} setIsOpenToBuy={setIsOpenToBuy}/>
                            </ModalDefault>
                        </>
                        :
                        <div className="d-flex flex-column justify-content-center px-4 pb-3">
                            { currentStepModal === 3
                                ? <Lottie animationData={SuccessLottie} style={{height: "150px"}} loop={false}/>
                                : currentStepModal === 4
                                    ? <Lottie animationData={ErrorLottie} style={{height: "150px"}} loop={false}/>
                                    : <LoadingModal/>
                            }

                            <span className="ff-normal text-center"> { (messageError) ? messageError : stepsMessage[currentMessageModal] } </span>

                            { currentStepModal === 3 &&
                                <>
                                    <div className="d-flex justify-content-center mt-2">
                                        <Link to={networkParams[nft.network]['scan']+txId} target="_blank" className="ff-bold">{t('mint.seeTransaction')}</Link>
                                    </div>

                                    <div className="d-flex justify-content-center flex-wrap gap-3 mt-4">
                                        <button className="btn btn-secondary w-25 w-auto" type="button" onClick={handleClose}>
                                            {t('privateKey.closeButton')}
                                        </button>
                                        <button
                                            className="btn btn-primary w-75"
                                            type="button"
                                            onClick={() => handleAddTokenToMetamask()}
                                        >
                                            {t('mint.addToken')}
                                        </button>
                                    </div>

                                </>
                            }

                            { currentStepModal === 4 && currentMessageModal !== 5 &&
                                <div className="d-flex justify-content-center gap-3 mt-4">
                                    <button
                                        className="btn btn-secondary w-50"
                                        type="button"
                                        onClick={() => handleModal()}
                                    >
                                        {t('mint.cancel')}
                                    </button>
                                    <button onClick={mint} className="btn btn-primary w-50">{t('mint.tryAgain')}</button>
                                </div>
                            }

                            { currentStepModal === 4 && currentMessageModal === 5 &&
                                <div className="d-flex justify-content-center gap-3 mt-4">
                                    <button
                                        className="btn btn-secondary w-50"
                                        type="button"
                                        onClick={() => handleModal()}
                                    >
                                        {t('privateKey.closeButton')}
                                    </button>
                                    <button onClick={() => handleAddTokenToMetamask()} className="btn btn-primary w-50">{t('mint.tryAgain')}</button>
                                </div>
                            }
                        </div>
                    }
                </>
            }
        </>
    )
}