import React from "react";
import videoPlaceholder from "assets/img/video-placeholder.png";
import {ImageContainer} from "../FileInput/styles";

export const FileList = ({ files, handleDelete }) => {
    return (
        <div className="row g-4 px-2">
            {files?.length > 0 &&
                files.map((file, index) => (
                    <div className="col-12 col-lg-4" key={index}>
                        <ImageContainer>
                            <div className="d-flex gap-2 align-items-center position-relative div-img">
                                <i className="fa-solid fa-circle-check text-success bg-light rounded-circle position-absolute" style={{ bottom: "2px", left: "32px" }} />
                                <img
                                    src={file.type === "video/mp4" ? videoPlaceholder : URL.createObjectURL(file)}
                                    alt={file.name}
                                    style={{ objectFit: "cover" }}
                                    height="50"
                                    width="50"
                                    className="rounded-3"
                                />

                                <div className="d-flex justify-content-end text-start float-start small">
                                    <b style={{ marginRight: 5 }}>{file.quantity ? file.quantity : 1}x</b> {file.name}
                                </div>
                            </div>

                            <div className="overlay position-absolute">
                                <button className="btn btn-sm btn-danger" type="button" onClick={() => handleDelete(index)}>
                                    <i className="fa fa-trash" />
                                </button>
                            </div>
                        </ImageContainer>
                    </div>
                ))}
        </div>
    );
};

export const FileListRejected = ({ files }) => {
    return (
        <div className="row g-2">
            {files?.length > 0 &&
                files.map(({ file, errors }) => (
                    <div className="col-12 border-bottom p-3" key={file.name}>
                        <div key={file.name} className="d-flex justify-content-between align-items-center">
                            <div className="d-flex gap-3 align-items-center position-relative">
                                <i className="fa-solid fa-circle-xmark text-danger bg-light rounded-circle position-absolute" style={{ bottom: "2px", left: "20px" }} />

                                <i className="fa-solid fa-file fs-1" />

                                <div className="d-grid">
                                    <span className="text-muted">{file.name}</span>

                                    {errors.map((e, index) => (
                                        <small className="text-danger" key={e.code}>
                                            {e.code === "file-invalid-type" && "Extensão inválida."}

                                            {e.code === "too-many-files" && "Limite de arquivos excedida."}
                                        </small>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};
