import {
  ConnectSection,
  ContentContainer,
  FundPageContainer,
  GridImages,
  ImagesProfileContainer,
  TextsContainer,
} from "./styles";

import Img00 from "assets/img/img-01.png";
import Img10 from "assets/img/img-02.png";

import Img01 from "assets/imageFounders/carol.png";
import Img02 from "assets/imageFounders/henrique.png";
import Img05 from "assets/imageFounders/jeff.png";
import Img03 from "assets/imageFounders/luiz.png";
import Img08 from "assets/imageFounders/paula.png";
import Img09 from "assets/imageFounders/rafaela.png";
import Img04 from "assets/imageFounders/sandro.png";
import Img07 from "assets/imageFounders/suelen.png";
import Img06 from "assets/imageFounders/victor.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const ImagesArray = [
  { id: 1, img: '', name: "" },
  { id: 2, img: Img01, name: "Carol Santos" },
  { id: 3, img: Img08, name: "Paula Palermo" },
  { id: 4, img: Img07, name: "Suelen Moróstica" },
  { id: 5, img: Img02, name: "Henrique Aragon" },
  { id: 6, img: Img06, name: "Victor Caribé" },
  { id: 7, img: Img05, name: "Jeff Beltrão" },
  { id: 8, img: Img03, name: "Luiz Hadad" },
  { id: 9, img: Img04, name: "Sandro Langer" },
];

export const FundPage = ({ isRequest = false }) => {
  const { t, i18n } = useTranslation();
  return (
    <FundPageContainer>
      <h4 style={{fontSize:'2rem'}}>
        {t('fundToEnd')}{" "}
        <div className="flower-container">
          <img src={Img00} alt="" />
        </div>
      </h4>

      <ContentContainer>
        <TextsContainer>
          {isRequest ? (
            <>
            <p>
            {t('resources2')} <br />{t('resources3')} <br /> {t('resources4')}
            </p>
            <p>
              <ol>
                <li>
                {t('resources5')}
                </li>
                <li>
                {t('resources6')} <br /> {t('resources7')}
                </li>
              </ol>
            </p>
            </>
          ) : (
            <>
            <p>
            {t('fundP1')} <br/> {t('fundP2')} <br/> {t('fundP3')} <br/> {t('fundP4')}
            </p>
            <p>
            {t('fundP5')} <br /> {t('fundP6')}<br />
            {t('fundP7')} <br /> {t('fundP8')} <br />
            {t('fundP9')} <br />
            {t('fundP10')}
            </p>
            </>
          )}
  
          {isRequest ? (
            <>
            <p>
            {t('resources8')}<br /> {t('resources9')} <br /> {t('resources10')}
            </p>
            <a
                    href="https://www.youtube.com/watch?v=S8ftgmMPZhs&t=14s"
                    target="_blank"
                    className="btn btn-lg btn-secondary rounded-pill py-3 ff-medium"
                    style={{ width: "100%", maxWidth: "350px" }}
                  >
                    {t('howToMetamask')}
                  </a>
            </>
          ) : (
            <Link
              to="/request"
              className="btn btn-lg btn-secondary rounded-pill py-3 ff-medium"
              style={{ width: "100%", maxWidth: "350px" }}
            >
             {t('resources')}
            </Link>
          )}
        </TextsContainer>
        <GridImages>
          {ImagesArray.map((item) => (
            <ImagesProfileContainer key={item.id}>
              <img src={item.img} alt="" />
              <p>{item.name}</p>
            </ImagesProfileContainer>
          ))}
          <strong style={{ textAlign: "center" }}>
          {t('committee')}
          </strong>
        </GridImages>
      </ContentContainer>

      <ConnectSection>
        <div className="container">
          <div className="row justify-content-center gx-">
            <div className="col-12 col-md-5 col-xl-3">
              <p className="ff-normal fs-5 mb-4" style={{ marginLeft: "45px" }}>
              {t('iAmDesigner')}
                <br /> {t('iAmDesigner2')}
                <br /> {t('iAmDesigner3')}
              </p>
              <button className="btn btn-lg bg-white rounded-pill py-3 px-5">
               {t('iAmDesigner4')}
              </button>
            </div>

            <div className="col-12 col-md-5 col-xl-3 offset-lg-1 mt-5 mt-md-0">
              <p style={{ marginLeft: "45px" }} className="ff-normal fs-5 mb-4">
              {t('iHaveProject')} <br /> {t('iHaveProject2')}
                <br /> designer.
              </p>
              <button className="btn btn-lg bg-white rounded-pill py-3 px-5">
              {t('iHaveProject3')}
              </button>
            </div>
          </div>
        </div>
      </ConnectSection>
    </FundPageContainer>
  );
};
