import styled from "styled-components";

export const CardCollect = styled.div`
  .card {
    height: 711px;
    background: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.black};
    border-radius: 20px;
    transition: transform 0.5s;
  }

  .card:hover {
    transform: translate(0, -8px);
  }

  .card-content-text {
    background: #ffffff;
    color: ${(props) => props.theme.primary};
  }

  .avatar img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    width: 2.5em;
    height: 2.5em;
    object-fit: cover;
  }

  .image-collection {
    width: 100%;
    height: 15rem;
    object-fit: cover;
    border: 2px solid #ffffff;
  }

  .chain {
    position: absolute;
    left: 1.5rem;
    top: 1.5rem;
    background-color: #3333334a;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    opacity: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity 0.8s;
  }

  :hover {
    .chain {
      opacity: 1;
    }
  }
`;

export const CardContentText = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  small{
    display: block;
    line-height: 16px;
  }

  height: ${(props) => props.height ?? "auto"};

  background: #ffffff;
  color: ${(props) => props.theme.black};

  border-radius: 25px;
  padding: 5px 0;
`;
