import Styled from 'styled-components'

export const Content = Styled.div`

    padding-bottom: 5rem;

    .banner-collection {
        position: relative;
        display: grid;
    }
    
    .banner-collection img {
        width: 100%;
        height: 350px;
        object-fit: cover;
    }
    
    .image-collection {
        margin-top: -160px;
        position: absolute;
        background-color: #4158D0;
    }
    
    .image-avatar-default {
        border-radius: 18px;
        border: 4px solid #FFFFFF;
        background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    }
    
    .image-collection img {
        width: 100%;
        height: 194px;
        border-radius: 18px;
        border: 4px solid #FFFFFF;
    }
        
    .date-format::first-letter {
        text-transform: uppercase;
    }
    
`;