import { CardContent } from "./styles";
import { networkParams } from "web3/networks/networks";
import { useTranslation } from 'react-i18next';

export const CardNFTPreview = ({ nft, network }) => {
    const { t, i18n } = useTranslation();
    const crypto = networkParams[network]?.crypto;
    return (
        <CardContent>
            <div className="card">
                <div className="card-image">
                    <img
                        src={URL.createObjectURL(nft)}
                        alt=""
                        role="button"
                    />
                </div>
                <div className="card-body mx-0 px-2 pt-2 pb-2">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex flex-column">
                            <span className="ff-bold text-xs">{nft.name}</span>
                            <span className="ff-medium text-xs text-nowrap">{nft.price} {crypto}</span>
                        </div>

                        <button
                            className="btn btn-outline-secondary btn-sm rounded-pill"
                            type="button"
                        >
                            {t('details')}
                        </button>
                    </div>
                </div>
            </div>
        </CardContent>
    );
};
