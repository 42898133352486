export const PlaceholderCard = () => {
    return(
        <div className="card w-100 placeholder" style={{ height: "500px", borderRadius: "20px"}}>
            <div className="card-body placeholder" style={{ height: "500px", borderRadius: "20px"}}/>
        </div>
    )
}

export const PlaceholderCards = () => {
    return(
        <div className="row gx-5 placeholder-glow">
            <div className="col-12 col-md-6 col-xl-4">
                <PlaceholderCard />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <PlaceholderCard />
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <PlaceholderCard />
            </div>
        </div>
    )
}

export const PlaceholderCardHome = () => {
    return(
        <div className="card w-100 placeholder" style={{ height: "290px", borderRadius: "20px"}}>
            <div className="card-body placeholder" style={{ height: "290px", borderRadius: "20px"}}/>
        </div>
    )
}

export const PlaceholderCardsHome = () => {
    return(
        <div className="row row-cols-2 row-cols-lg-5 g-2 g-lg-3 placeholder-glow">
            <div className="col">
                <PlaceholderCardHome />
            </div>
            <div className="col">
                <PlaceholderCardHome />
            </div>
            <div className="col">
                <PlaceholderCardHome />
            </div>
            <div className="col">
                <PlaceholderCardHome />
            </div>
            <div className="col">
                <PlaceholderCardHome />
            </div>
        </div>
    )
}