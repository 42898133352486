import api from 'services/api';

export const useApi = () => ({
    validateToken: async (token) => {
        const response = await api.post('/validate', { token });
        return response.data
    },
    signin: async (email, password) => {
        const response = await api.post('/verifyPassword', { email, password });
        return response.data
    },
    logout: async (token) => {
        const response = await api.post('/logout', { token });
        return response.data
    },
    getUser: async (id) => {
        const response = await api.get(`/user?id=${id}`);
        return response.data
    },
})

export const useUsers = () => ({
    getUser: async (id) => {
        const response = await api.get(`/user?id=${id}`);
        return response.data
    },
    listUsers: async (status) => {
        let filter = '';
        if (status) {
            filter = '?status='+status;
        }

        const response = await api.get(`/listUsers${filter}`);
        return response.data
    },
    createUser: async (data) => {
        const response = await api.post(`/createUser`, data);
        return response.data
    },
    updateUser: async (data) => {
        const response = await api.post(`/updateUser`, data);
        return response.data
    },
})

export const useCollection  = () => ({
    getCollections: async (status = '', name = '') => {

        let filter = '';
        if (status) {
            filter = 'status='+status;
        }

        if (name) {
            filter = filter + '&name='+name;
        }

        const response = await api.get(`/collections?${filter}`);
        return response.data
    },
    getCollection: async (id) => {
        const response = await api.get(`/getcollection?collection=${id}`);
        return response.data
    },
    updateCollection: async (data) => {
        const response = await api.post(`/updateCollection`, data);
        return response.data
    }
})


export const useCategories = () => ({
    getCategory: async (id) => {
        const response = await api.get(`/categories?id=${id}`);
        return response.data.data
    },
    getCategories: async () => {
        const response = await api.get(`/categories`);
        return response.data.data
    },
    createCategory: async (data) => {
        const response = await api.post(`/createCategory`, data);
        return response.data
    },
    updateCategory: async (data) => {
        const response = await api.post(`/updateCategory`, data);
        return response.data
    },
})