import React from "react";
import { Routes, Route } from "react-router-dom";

import { LayoutApp } from "./layouts/AppLayout";
import { LayoutAdmin } from "./layouts/AdminLayout";
import { LayoutLogin } from "./layouts/LoginLayout";

// import { HomePage } from 'pages/Home';
import { CreateNftPage } from "pages/CreateNFT";
import { CollectionPage } from "pages/Collection";
import { MarketplacePage } from "pages/OurProjects";
import { CollectionDetail } from "pages/CollectionDetail";
import { CreatorsPage } from "pages/Creators";
import { CreatorPage } from "pages/Creator";
import { FormFunds } from "pages/Form";

import { LoginForm } from "admin/pages/Login";
import Dashboard from "./admin/pages/Dashboard";
import CollectionsAdmin from "./admin/pages/Collections";
import { ModalAdmin } from "./admin/components/Modal";
import { FormCollection } from "./admin/pages/Collections/components/FormCollection";
import { RequireAuth } from "./admin/contexts/Auth/RequireAuth";
import UsersAdmin from "./admin/pages/Users";
import { FormUser } from "./admin/pages/Users/components/FormUser";
import { CategoriesAdmin } from "./admin/pages/Categories";
import { FormCategory } from "./admin/pages/Categories/components/FormCategory";

import { QueryClientProvider, QueryClient } from "react-query";
import { Profile } from "./pages/Profile";
import { Error404 } from "./pages/Error404";
import { CollectionProvider } from "./web3/providers/CollectionContext";
import { HomePage } from "pages/Home";
import { useApp } from "web3/providers/AppContext";
import { Navigate } from "react-router-dom";
import { FundPage } from "pages/FundPage";
import { RequestPage } from "pages/RequestPage";
import { SalesAdmin } from "admin/pages/Sales";
const queryClient = new QueryClient();

const WalletAuth = ({ children }) => {
  const { wallet } = useApp();

  if (!wallet) {
    return <Navigate to="/" />;
  }
  return children;
};

function AppRouter() {
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutApp />}>
          <Route index element={<HomePage />} />
          <Route path="create" element={<CreateNftPage />} />
          <Route path="fund" element={<FundPage />} />
          <Route path="request" element={<RequestPage />} />
          <Route path="collection/:collection" element={<CollectionPage />} />
          <Route path="creators" element={<CreatorsPage />} />
          <Route path="creator/:param" element={<CreatorPage />} />
          <Route path="form" element={<FormFunds />} />

          <Route path="404" element={<Error404 />} />
          <Route
            path="collection/:collection/:id"
            element={
              <CollectionProvider>
                <CollectionDetail />
              </CollectionProvider>
            }
          />
          <Route path="our-project" element={<MarketplacePage />} />

          <Route
            path="profile/:tab"
            element={
              <WalletAuth>
                <Profile />
              </WalletAuth>
            }
          />
          <Route path="*" element={<Error404 />} />
        </Route>

        <Route path="/login" element={<LayoutLogin />}>
          <Route index element={<LoginForm />} />
        </Route>

        <Route
          path="/admin"
          exact
          element={
            <QueryClientProvider client={queryClient}>
              <RequireAuth>
                <LayoutAdmin />
              </RequireAuth>
            </QueryClientProvider>
          }
        >
          <Route path="dashboard" index element={<Dashboard />} />
          <Route path="sales" index element={<SalesAdmin />} />
          <Route path="collections" element={<CollectionsAdmin />}>
            <Route
              path="edit/:id"
              element={
                <ModalAdmin location="collections">
                  <FormCollection />
                </ModalAdmin>
              }
            />
          </Route>

          <Route path="users" element={<UsersAdmin />}>
            <Route
              path="add"
              element={
                <ModalAdmin location="users" label="Adicionar novo usuário">
                  <FormUser />
                </ModalAdmin>
              }
            />
            <Route
              path="edit/:id"
              element={
                <ModalAdmin location="users" label="Editar usuário">
                  <FormUser />
                </ModalAdmin>
              }
            />
          </Route>

          <Route path="categories" element={<CategoriesAdmin />}>
            <Route
              path="add"
              element={
                <ModalAdmin location="categories" label="Adicionar categoria">
                  <FormCategory />
                </ModalAdmin>
              }
            />
            <Route
              path="edit/:id"
              element={
                <ModalAdmin location="categories" label="Editar categoria">
                  <FormCategory />
                </ModalAdmin>
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default AppRouter;
