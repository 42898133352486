import {Formik, Field, Form} from 'formik';
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useUsers} from "../../../../hooks/useApi";
import {useMutation, useQueryClient} from "react-query";
import {Toast} from "../../../../components/Alert";

export const FormUser = () => {

    const [user, setUser] = useState(null)

    const {id} = useParams()
    const navigate = useNavigate()
    const api = useUsers()
    const queryClient = useQueryClient()

    useEffect(() => {
        const request = async () => {
            const response = await api.getUser(id)
           
            setUser(response.data)
        }

        request()

    }, [id])

    const create = async (values) => {
        createUserMutation.mutate(values)
    }

    const update = async (values) => {
        const dataUpdate = {
            'id': id,
            'name': values.name,
            'status': values.status
        }
        updateUserMutation.mutate(dataUpdate)
    }

    const updateUserMutation = useMutation(async (data) => {
            return api.updateUser(data)
        }, {
            onSuccess: (res) => {
                Toast.fire({
                    icon: 'success',
                    title: 'Usuário alterado com sucesso!'
                })
                queryClient.invalidateQueries(["users_list"])
                navigate(`/admin/users`);
            },
            onError: (err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            }
        }
    )

    const createUserMutation = useMutation(async (data) => {
            return api.createUser(data)
        }, {
            onSuccess: (res) => {
                if (!res.id) {
                    Toast.fire({
                        icon: 'error',
                        title: res.error
                    })
                    return
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Usuário criado com sucesso!'
                })
                queryClient.invalidateQueries(["users_list"])
                navigate(`/admin/users`);
            },
            onError: (err) => {
                Toast.fire({
                    icon: 'error',
                    title: 'Falha na requisição'
                })
            }
        }
    )

    return (
        <div className="px-4">
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: user?.name ?? '',
                    email: user?.email ?? '',
                    password: '',
                    status: user?.status
                }}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    

                    id
                        ? update(values)
                        : create(values)
                }}
            >
                {
                    ({isSubmitting}) => (
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Nome</label>
                                <Field
                                    id="name"
                                    name="name"
                                    placeholder=""
                                    className="form-control"
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <Field
                                    id="email"
                                    name="email"
                                    placeholder=""
                                    type="email"
                                    className="form-control"
                                    required
                                    disabled={id && true}
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="password" className="form-label">Senha</label>
                                <Field
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="********"
                                    className="form-control"
                                    required={!id && true}
                                />
                            </div>

                            <div className="card p-3">
                                <div className="d-flex justify-content-between">
                                    <label className="form-check-label" htmlFor="active">
                                        Status
                                    </label>
                                    <div className="form-check form-switch">
                                        <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="status"
                                            name="status"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="pt-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100"
                                    disabled={isSubmitting}
                                >
                                    { isSubmitting
                                        ? <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="visually-hidden">Aguarde...</span>
                                          </div>
                                        : 'Salvar'
                                    }
                                </button>
                            </div>
                        </Form>
                    )
                }
            </Formik>
        </div>
    )
}