import {A11y, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';

import {CarouselContainer} from './styles'
import {faCaretLeft, faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardCollection from "../CardCollection";
import BigCardCollection from "components/CardCollection";
import { useEffect, useState } from "react";

export const Carousel = ({collections,isLastProjects=false}) => {
    const [sortedCollections, setSortedCollections] = useState([]);

    useEffect(() => {
        const sortCollections = () => {
          const sorted = [...collections].sort((a, b) => {
           
            if (a.createdAt > b.createdAt) {
              return -1; 
            }
            if (a.createdAt < b.createdAt) {
              return 1; 
            }
            return 0; 
          });
    
          setSortedCollections(sorted);
        };
    
        sortCollections();
      }, [collections]);

    return (
        <CarouselContainer>
            <Swiper
                modules={[Navigation, A11y]}
                loop={true}
                navigation={{
                    prevEl: '.swiper-button-next',
                    nextEl: '.swiper-button-prev',
                }}
                breakpoints={{
                    0: {
                        slidesPerView: isLastProjects ? 1 : 1,
                        spaceBetween: 50
                    },
                    576: {
                        slidesPerView: isLastProjects ? 1 : 2,
                        spaceBetween: 50
                    },
                    992: {
                        slidesPerView: isLastProjects ? 2 : 3,
                        spaceBetween: 50
                    },
                    1199: {
                        slidesPerView: isLastProjects ? 3 : 5,
                        spaceBetween: 35
                    }
                }}
                onSlideChange={() => {}}
                onSwiper={(swiper) => {}}
            >

                <div className="buttons-swiper d-none d-md-block">
                    <div className="swiper-button-next">
                        <FontAwesomeIcon icon={faCaretRight} />
                    </div>
                    <div className="swiper-button-prev">
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </div>
                </div>

                { sortedCollections?.map((collection, index) =>
                    <SwiperSlide  key={index}>
                        {isLastProjects ? <BigCardCollection creator={collection?.creator} collection={collection} /> : (   <CardCollection
                            creator={collection?.creator}
                            collection={collection}
                        />)}
                    </SwiperSlide>
                )}
            </Swiper>

        </CarouselContainer>
    )
}