import {useEffect, useState} from "react";

import {Carousel} from "../Carousel";

import {getCollections} from "services/collection.service";
import { PlaceholderCards } from "components/Placeholder";
import { useTranslation } from 'react-i18next';


export const LastProjects = () => {
    const [collections, setCollections] = useState([]);
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false)

    const fetchCollections = async () => {
        setLoading(true)
        const response = await getCollections()
        setCollections(response);
        setLoading(false)
    }

    useEffect(() => {
        fetchCollections()
    },[])


    return (
        <>
            { loading ?
                <div className="container mt-4 px-5">
                   <PlaceholderCards />
                </div>
                :
                <div>
                    <div className="container px-lg-2 mt-5">
                        <div className="mb-5">
                            <h2 className="label-custom mb-0">{t('lastProjects')}</h2>
                            <Carousel
                                isLastProjects
                                collections={collections}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}