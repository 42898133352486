import Lottie from "lottie-react";
import SuccessLottie from "assets/img/lottie/success.json";
import ErrorLottie from "assets/img/lottie/error.json";

import {LoadingModal} from "components/Loading";
import {Link} from "react-router-dom";
import React from "react";
import {useApp} from "web3/providers/AppContext";
import { useTranslation } from 'react-i18next';

export const ProcessDeploy = ({
                                  currentStepModal,
                                  currentMessageModal,
                                  handleCancelModal,
                                  handleConfirmModal,
                                  formik,
                                  tryAgainAction,
                                  counter
}) => {
    const { t, i18n } = useTranslation();
    const { wallet, connectWallet } = useApp();
    const enableCounter = counter?.total > 0 && currentStepModal === 2

    const stepsMessage = [
        t('validatingInfo'),
        t('sendingImages'),
        t('creatingProject'),
        t('collectionCreated'),
        t('changeNetwork'),
        t('insufficientBalance'),
        t('confirmTransaction'),
        t('confirmingContractUpdate'),
        t('savingInfo'),
        t('collectionGenerationFailure'),
        t('awaitingConfirmation'),
        t('transactionConfirmed')
    ]

    return (
        <>
            { currentStepModal === 0 ?
                <div
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                    }}>

                    <div className="d-flex align-items-center justify-content-center my-2">
                        <h4 className="ff-bold">{t('welcome')}</h4>
                    </div>

                    <p> {t('percentagesWarning')}</p>

                    <p className="ff-normal px-4 mb-4">
                        {t('connectingAgree')} <a
                        href="https://drive.google.com/file/d/1Lzsu8JDYzCFf9tXc7YHSBpsaZWleztw2/view?usp=drive_link" target="_blank">{t('terms')}</a> e{" "}
                        <a href="https://drive.google.com/file/d/1Lzsu8JDYzCFf9tXc7YHSBpsaZWleztw2/view?usp=drive_link" target="_blank">{t('privacyPolicy')}</a>
                    </p>

                    <div className="d-flex justify-content-center gap-3 px-4">
                        <button
                            className="btn btn-secondary w-50"
                            type="button"
                            onClick={() => handleCancelModal(formik)}
                        >
                            {t('mint.cancel')}
                        </button>

                        { !wallet ?
                            <button
                                className="btn btn-primary w-50"
                                onClick={() => connectWallet()}
                            >
                              {t('mint.connectOrCreate')}
                            </button>

                            :
                            <button
                                className="btn btn-primary w-50"
                                onClick={() => handleConfirmModal(formik)}
                            >
                                {t('acceptContinue')}
                            </button>
                        }
                    </div>
                </div>
                :
                <div className="d-flex flex-column justify-content-center mb-3">

                    { currentStepModal === 3
                        ? <Lottie animationData={SuccessLottie} style={{height: "150px"}} loop={false}/>
                        : currentStepModal === 4
                        ? <Lottie animationData={ErrorLottie} style={{height: "150px"}} loop={false}/>
                            : <LoadingModal/>
                    }

                    <div className="d-flex flex-column gap-4 justify-content-center px-4">
                        <span className="ff-normal text-center">
                            {stepsMessage[currentMessageModal]}
                            
                        </span>
                        {enableCounter && <span className="ff-normal text-center"><strong>{counter?.id}</strong> / {counter.total} </span>}
                        

                        { currentStepModal === 4 &&
                            <div className="d-flex justify-content-center gap-3">
                                <button
                                    className="btn btn-secondary w-50"
                                    type="button"
                                    onClick={() => handleCancelModal(formik)}
                                >
                                    {t('mint.cancel')}
                                </button>
                                <button onClick={() => tryAgainAction(formik)} className="btn btn-primary w-50">{t('mint.tryAgain')}</button>
                            </div>
                        }

                        { currentStepModal === 3 &&
                            <div className="d-flex gap-3">
                                <Link to="/" className="btn btn-secondary w-50">{t('toHome')}</Link>
                                <Link to="/profile/my-projects" className="btn btn-primary w-50">{t('myAccount')}</Link>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}