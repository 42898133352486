import Select from "react-select";
import { useTranslation } from 'react-i18next';

export const CustomSelect = ({ onChange, options, value, error }) => {
    const { t, i18n } = useTranslation();
    const handleChange = values => {
        const items = values.map(item => item.value);
        onChange(items);
    };

    return (
        <Select
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: !error ? "#ced4da" : error && 'red',
                    backgroundColor: state.isFocused ? "#FFFFFF" : "#FFFFFF",
                    fill: "#ced4da",
                    height: 50,
                    borderRadius: 8,
                    boxShadow: "0 0 0 1px transparent",
                    "&:active": {
                        borderColor: "#ced4da",
                        boxShadow: "0 0 0 1px transparent",
                    },
                    "&:blur": {
                        borderColor: "",
                        boxShadow: "0 0 0 1px #ff66af",
                    },
                    "&:hover": {
                        borderColor: "#ced4da",
                        //boxShadow: "0 0 0 1px transparent",
                    },
                    "&:focus": {
                        borderColor: "#ced4da",
                    },
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    borderTop: 0,
                    borderColor: state.isFocused ? "red" : "#626262",
                    backgroundColor: state.isFocused ? "#FFF" : "#FFF",
                }),
                multiValue: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#FFFFFF",
                }),
                multiValueLabel: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#333333",
                }),
                multiValueRemove: (baseStyles, state) => ({
                    ...baseStyles,
                    color: "#ff66af",
                }),
                noOptionsMessage: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#FFFFFF",
                }),
            }}
            closeMenuOnSelect={false}
            placeholder={t('selectCategories')}
            value={options?.filter(option => value.includes(option.value))}
            onChange={value => handleChange(value)}
            options={options}
            noOptionsMessage={() => t('allCategoriesSelected')}
            isMulti
        />
    );
};
