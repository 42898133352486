import {Link} from "react-router-dom";

export const Error404 = () => {
    return (
        <div className="container my-5" style={{height: "calc(100vh - 20rem)"}}>
            <div className="d-grid pt-5 gap-4 align-items-center justify-content-center text-center">
                <h1 className="mb-2 text-primary" style={{fontSize: "12rem", fontFamily: "'Roboto Mono', monospace"}}>404</h1>
                <span className="fs-2" style={{fontFamily: "'Roboto', monospace"}}>Oops! Página não encontrada</span>

                <Link to="/" className="btn btn-secondary btn-lg">Voltar a página inicial</Link>
            </div>
        </div>
    )
}